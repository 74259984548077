import axios from "axios";

export async function subscribeCreator(subscribeCreatorData: { username: string; token: string }) {
    const url = 'https://api.thez.ai/creator/' + subscribeCreatorData.username + '/subscribe'
    try {
        const response = await axios({
            method: 'post',
            url: url,
            headers: {
                'Authorization': `Bearer ${subscribeCreatorData.token}` // Include the token in the Authorization header
            }
        })
        return response.data;
    } catch (error) {
        console.error("Failed to subscribe to creator", error);
    }
}

export async function unsubscribeCreator(subscribeCreatorData: { username: string; token: string }) {
    const url = 'https://api.thez.ai/creator/' + subscribeCreatorData.username + '/unsubscribe'
    try {
        const response = await axios({
            method: 'post',
            url: url,
            headers: {
                'Authorization': `Bearer ${subscribeCreatorData.token}` // Include the token in the Authorization header
            }
        })
        return response.data;
    } catch (error) {
        console.error("Failed to unsubscribe to creator", error);
    }
}