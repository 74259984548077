import {useParams} from "react-router-dom";
import {
    Avatar,
    Box,
    Divider,
    Flex,
    Heading,
    Link,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text
} from "@chakra-ui/react";
import React, {useContext, useEffect, useState} from "react";
import {IContentCardProps, IUser, UserContext} from "./Provider";
import {BioDataCard, ContentCard} from "./Card";
import {BlueTick} from "./Core";
import axios from "axios";

export default function User() {
    const [user, setUser] = useState<IUser>()
    const [userPosts, setUserPosts] = useState<IContentCardProps[]>([])
    const [fetchErr, setFetchErr] = useState<string | null>(null)

    const userContext = useContext(UserContext);
    const currentUser = userContext?.currentUser

    const {username} = useParams();
    console.log("Loading user@" + username)

    useEffect(() => {
        const fetchUser = async () => {
            const token = currentUser?.token || ""

            try {
                const response = await axios({
                    method: 'get',
                    url: `https://api.thez.ai/user/${username}`,
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })

                if (response.data === 0) {
                    setFetchErr("No contents returned from the server");
                } else {
                    setUser(response.data as IUser);
                    console.log("Fetched user: " + user)
                }
            } catch (error) {
                setFetchErr("Failed to fetch user.");
                console.error("Failed to fetch user", error);
            }
        };

        const fetchUserPosts = async () => {
            const token = currentUser?.token || ""

            try {
                const response = await axios({
                    method: 'get',
                    url: `https://api.thez.ai/user/${username}/posts`,
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })

                if (response.data === 0) {
                    setFetchErr("No posts returned from the server");
                } else {
                    setUserPosts(response.data as IContentCardProps[]);
                    console.log("Fetched " + userPosts.length + " posts")
                }
            } catch (error) {
                setFetchErr("Failed to fetch user posts.");
                console.error("Failed to fetch user posts", error);
            }
        };

        (async () => {
            await fetchUser();
            await fetchUserPosts();
        })();
    }, [currentUser, username]);

    if (fetchErr) {
        return <Text>Error: {fetchErr}</Text>;
    }

    // Set some mock data.
    // const userPosts = mockUserSavedPosts(username!)

    console.log("User.name " + user?.name)
    console.log("User.display " + user?.display)
    console.log("useSavedPosts: " + userPosts?.length)

    if (!user || !userPosts) {
        return <Box>Loading...</Box>;
    }

    return (
        <Box>
            <BioDataCard
                type='user'
                name={user.name}
                username={user.username}
                display={user.display}
                topics={user.interests}
                bio={user.bio}
                connected={user.connected}
            />

            <Box
                maxW="100%"
            >
                <Tabs>
                    <TabList>
                        <Tab
                            key='posts'
                            px={6}
                        >
                            <Heading as='h4' size='md'>
                                Posts
                            </Heading>
                        </Tab>

                        <Tab
                            key='following'
                            px={6}
                        >
                            <Heading as='h4' size='md'>
                                Following
                            </Heading>
                        </Tab>

                        <Tab
                            key='followers'
                            px={6}
                        >
                            <Heading as='h4' size='md'>
                                Followers
                            </Heading>
                        </Tab>

                        <Tab
                            key='subscriptions'
                            px={6}
                        >
                            <Heading as='h4' size='md'>
                                Subscriptions
                            </Heading>
                        </Tab>
                    </TabList>

                    <TabPanels p={6}>
                        {/*First Tab*/}
                        <TabPanel
                            key='posts'
                            minW='100%'
                            maxW='100%'
                        >
                            {userPosts.map((userPost, index) => (
                                <ContentCard
                                    post_uuid={userPost.post_uuid}
                                    query={userPost.query}
                                    response={userPost.response}
                                    links={userPost.links}
                                    creator={userPost.creator}
                                    tags={userPost.tags}
                                    visibility={userPost.visibility}
                                    status={userPost.status}
                                />
                            ))}
                        </TabPanel>

                        {/*Second Tab*/}
                        <TabPanel
                            key='posts'
                            minW='100%'
                            maxW='100%'
                        >
                            <Box
                                p={4}
                                borderRadius="md"
                                border="1px solid"
                                borderColor="gray.200"
                                _hover={{
                                    backgroundColor: "gray.50",
                                    borderWidth: "2px solid",
                                }}
                            >
                                {user?.following_data?.map((userFollowing, index) => (
                                    <React.Fragment key={index}>
                                        <Flex alignItems="left" justifyContent="left" py='2'>
                                            <Avatar
                                                name={userFollowing?.name}
                                                src={userFollowing?.display}
                                                size="md"
                                            />

                                            <Box
                                                ml={3}
                                                textAlign="left"
                                            >
                                                <Link href={`/user/${userFollowing.username}`}>
                                                    <Text
                                                        as="span"
                                                        fontSize="lg"
                                                        fontWeight="bold"
                                                        color="black"
                                                        _hover={{textDecoration: "underline"}}
                                                    >
                                                        {userFollowing.name}
                                                    </Text>
                                                </Link>
                                                <Box fontSize="sm" color="gray.600">
                                                    @{userFollowing.username}
                                                </Box>
                                            </Box>

                                            {/*/!* Follow button *!/*/}
                                            {/*<Button*/}
                                            {/*    colorScheme="gray"*/}
                                            {/*    size="md"*/}
                                            {/*    ml="auto"*/}
                                            {/*>*/}
                                            {/*    Follow*/}
                                            {/*</Button>*/}
                                        </Flex>
                                        {index < user?.following_data!.length - 1 && (
                                            <Divider borderColor="gray.300" opacity={0.5} my={2}/>
                                        )}
                                    </React.Fragment>
                                ))}
                            </Box>
                        </TabPanel>

                        {/*Third Tab*/}
                        <TabPanel
                            key='posts'
                            minW='100%'
                            maxW='100%'
                        >
                            <Box
                                p={4}
                                borderRadius="md"
                                border="1px solid"
                                borderColor="gray.200"
                                _hover={{
                                    backgroundColor: "gray.50",
                                    borderWidth: "2px solid",
                                }}
                            >
                                {user?.followers_data?.map((userFollower, index) => (
                                    <React.Fragment key={index}>
                                        <Flex alignItems="left" justifyContent="left" py='2'>
                                            <Avatar
                                                name={userFollower?.name}
                                                src={userFollower?.display}
                                                size="md"
                                            />

                                            <Box
                                                ml={3}
                                                textAlign="left"
                                            >
                                                <Link href={`/user/${userFollower.username}`}>
                                                    <Text
                                                        as="span"
                                                        fontSize="lg"
                                                        fontWeight="bold"
                                                        color="black"
                                                        _hover={{textDecoration: "underline"}}
                                                    >
                                                        {userFollower.name}
                                                    </Text>
                                                </Link>
                                                <Box fontSize="sm" color="gray.600">
                                                    @{userFollower.username}
                                                </Box>
                                            </Box>

                                            {/*/!* Follow button *!/*/}
                                            {/*<Button*/}
                                            {/*    colorScheme="gray"*/}
                                            {/*    size="md"*/}
                                            {/*    ml="auto"*/}
                                            {/*>*/}
                                            {/*    Follow*/}
                                            {/*</Button>*/}
                                        </Flex>
                                        {index < user?.followers_data!.length - 1 && (
                                            <Divider borderColor="gray.300" opacity={0.5} my={2}/>
                                        )}
                                    </React.Fragment>
                                ))}
                            </Box>
                        </TabPanel>

                        {/*Fourth Tab*/}
                        <TabPanel
                            key='posts'
                            minW='100%'
                            maxW='100%'
                        >
                            <Box
                                p={4}
                                borderRadius="md"
                                border="1px solid"
                                borderColor="gray.200"
                                _hover={{
                                    backgroundColor: "gray.50",
                                    borderWidth: "2px solid",
                                }}
                            >
                                {user?.subscriptions_data?.map((userSubscription, index) => (
                                    <React.Fragment key={index}>
                                        <Flex alignItems="left" justifyContent="left" py='2'>
                                            <Avatar
                                                name={userSubscription.name}
                                                src={userSubscription.display}
                                                size="md"
                                            />

                                            <Box
                                                ml={3}
                                                textAlign="left"
                                            >
                                                <Link href={`/creator/${userSubscription.username}`}>
                                                    <Text
                                                        as="span"
                                                        fontSize="lg"
                                                        fontWeight="bold"
                                                        color="black"
                                                        _hover={{textDecoration: "underline"}}
                                                    >
                                                        {userSubscription.name}<BlueTick size={'sm'}/>
                                                    </Text>
                                                </Link>
                                                <Box fontSize="sm" color="gray.600">
                                                    @{userSubscription.username}
                                                </Box>
                                            </Box>

                                            {/*/!* Subscribe button *!/*/}
                                            {/*<Button*/}
                                            {/*    colorScheme="gray"*/}
                                            {/*    size="md"*/}
                                            {/*    ml="auto"*/}
                                            {/*>*/}
                                            {/*    Subscribe*/}
                                            {/*</Button>*/}
                                        </Flex>
                                        {index < user?.subscriptions_data!.length - 1 && (
                                            <Divider borderColor="gray.300" opacity={0.5} my={2}/>
                                        )}
                                    </React.Fragment>
                                ))}
                            </Box>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </Box>
    )
}
