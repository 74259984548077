import {Box, Button, Flex, Image, List, ListItem, Stack, Text} from "@chakra-ui/react";
import React, {useContext, useEffect, useState} from "react";
import {motion, Variants} from "framer-motion";
import {Form, useNavigate} from "react-router-dom";
import Waitlist from "./Waitlist";
import Preview from "./Preview";
import {UserContext} from "./Provider";

export default function Landing() {
    const [showPreview, setShowPreview] = useState(false);
    const [showWaitlist, setShowWaitlist] = useState(false);


    const userContext = useContext(UserContext);
    const navigate = useNavigate();

    const MotionText = motion(Text);
    const MotionListItem = motion(ListItem);

    // Navigate to Home if user is logged in
    useEffect(() => {
        if (userContext?.currentUser?.token) {
            navigate('/home');
        }
    }, [userContext, navigate]);


    const toggleShowPreview = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        // setShowWaitlist(false)
        // setShowPreview(!showPreview)
        navigate('/try')
    }

    const toggleShowWaitlist = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setShowPreview(false)
        setShowWaitlist(!showWaitlist)
    }


    // Animation Variants
    const textFadeIn: Variants = {
        hidden: {opacity: 0},
        visible: {opacity: 1, transition: {duration: 1}}
    };

    const listItemSlideIn: Variants = {
        hidden: {x: 300, opacity: 0},
        visible: (custom: number) => ({
            x: 0,
            opacity: 1,
            transition: {delay: custom * 0.5, duration: 1}
        })
    };

    return (
        <Box>
            {/* Logo positioned fixed at the top right */}
            <Flex position="fixed" top={8} left={8} align="center" zIndex="10">
                <Image src="/logo512.png" width="100px" height="100px" alt="Logo"/>
            </Flex>

            <Box>
                {/*First row*/}
                <Box
                    position="relative"
                    minH="100vh" // Set the minimum height to 100% of the viewport
                    bgImage="url(/bg/bg2.png)" // Set the background image
                    bgSize="cover" // Make sure the image covers the full container
                    bgPosition="center"
                    textAlign="center"
                >
                    {showPreview && <Preview/>}

                    {showWaitlist && <Waitlist/>}

                    <Flex
                        position="absolute"
                        bottom={20}
                        right={20}
                        direction="row"
                    >
                        {/*Preview*/}
                        <Form onSubmit={toggleShowPreview}>
                            <Button
                                type='submit'
                                variant="ghost"
                                color="white"
                                fontWeight="bold"
                                fontSize="lg"
                                size="lg"
                                border={"2px solid white"}
                                borderRadius="full"
                                _hover={{color: "black", bg: "white", border: "2px solid black"}}
                                marginRight={4}
                            >
                                Preview
                            </Button>
                        </Form>

                        {/*Waitlist*/}
                        <Form onSubmit={toggleShowWaitlist}>
                            <Button
                                type='submit'
                                variant="ghost"
                                color="white"
                                fontWeight="bold"
                                fontSize="lg"
                                size="lg"
                                border={"2px solid white"}
                                borderRadius="full"
                                _hover={{color: "black", bg: "white", border: "2px solid black"}}
                                marginRight={4}
                            >
                                Waitlist
                            </Button>
                        </Form>
                    </Flex>
                </Box>

                {/*Second row*/}
                <Flex
                    height="100vh"
                    justifyContent="center"
                    alignItems="center"
                    position="relative"
                    textAlign="center"
                >
                    <Stack>
                        <Box
                            width="100%"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <MotionText
                                fontFamily="AvenirNextBold"
                                fontSize='6xl'
                                initial="hidden"
                                animate="visible"
                                variants={textFadeIn}
                            >
                                <Text>
                                    existing content platforms are failing us
                                </Text>
                            </MotionText>
                        </Box>

                        <Box
                            width="100%"
                            justifyContent="center"
                            alignItems="center"
                            marginTop={5}
                        >
                            <List spacing={3}>
                                <MotionListItem
                                    custom={1}
                                    initial="hidden"
                                    animate="visible"
                                    variants={listItemSlideIn}
                                >
                                    <Text fontFamily="AvenirNextBold" fontSize='4xl'>
                                        biased algorithms
                                    </Text>
                                </MotionListItem>
                                <MotionListItem
                                    custom={2}
                                    initial="hidden"
                                    animate="visible"
                                    variants={listItemSlideIn}
                                >
                                    <Text fontFamily="AvenirNextBold" fontSize='4xl'>
                                        subpar content
                                    </Text>
                                </MotionListItem>
                                <MotionListItem
                                    custom={3}
                                    initial="hidden"
                                    animate="visible"
                                    variants={listItemSlideIn}
                                >
                                    <Text fontFamily="AvenirNextBold" fontSize='4xl'>
                                        excessive sales
                                    </Text>
                                </MotionListItem>
                            </List>
                        </Box>
                    </Stack>
                </Flex>

                {/*Third row*/}
                <Flex
                    height="100vh"
                    justifyContent="center"
                    alignItems="center"
                    position="relative"
                    textAlign="center"
                >
                    <Stack>
                        <Box
                            width="100%"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Text as={'span'} fontFamily="AvenirNextBold" fontSize='5xl'>
                                our mission is to
                            </Text>
                            <Text as={'span'} fontFamily="AvenirNextBold" fontSize={'6xl'} color='blue.500'>
                                {' '}raise
                            </Text>
                        </Box>

                        <Box
                            width="100%"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <MotionText
                                fontFamily="AvenirNextBold"
                                fontSize='7xl'
                                initial="hidden"
                                animate="visible"
                                variants={textFadeIn}
                            >
                                <Text>
                                    the collective human consciousness
                                </Text>
                            </MotionText>
                        </Box>

                        <Box
                            width="100%"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <MotionText
                                fontFamily="AvenirNextBold"
                                fontSize='4xl'
                                initial="hidden"
                                animate="visible"
                                variants={textFadeIn}
                            >
                                <Text>
                                    ask anything, to anyone, anywhere
                                </Text>
                            </MotionText>
                        </Box>
                    </Stack>
                </Flex>

                {/*Fourth row*/}
                <Flex
                    position="relative"
                    height="100vh"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                >
                    <Stack>
                        <Box
                            width="100%"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <MotionText
                                fontFamily="AvenirNextBold"
                                fontSize='6xl'
                                initial="hidden"
                                animate="visible"
                                variants={textFadeIn}
                            >
                                <Text as={'span'}>
                                    the information renaissance is
                                </Text>
                                <Text as={'span'} color={'green.500'}>
                                    {' '}starting
                                </Text>
                            </MotionText>
                        </Box>

                        <Box
                            width="100%"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <MotionText
                                fontFamily="AvenirNextBold"
                                fontSize='4xl'
                                initial="hidden"
                                animate="visible"
                                variants={textFadeIn}
                            >
                                <Text>
                                    join the waitlist
                                </Text>
                            </MotionText>
                        </Box>
                    </Stack>
                </Flex>

                {/*Fifth row*/}
                <Box
                    position="relative"
                    minH="100vh" // Set the minimum height to 100% of the viewport
                    bgImage="url(/bg/bg1.png)" // Set the background image
                    bgSize="cover" // Make sure the image covers the full container
                    bgPosition="center"
                    textAlign="center"
                >
                    <Preview/>
                </Box>
            </Box>
        </Box>
    );
}
