import axios from "axios";

export async function followUser(followUserData: { username: string; token: string }) {
    const url = 'https://api.thez.ai/user/' + followUserData.username + '/follow'
    try {
        const response = await axios({
            method: 'post',
            url: url,
            headers: {
                'Authorization': `Bearer ${followUserData.token}` // Include the token in the Authorization header
            }
        })
        return response.data;
    } catch (error) {
        console.error("Failed to follow user", error);
    }
}

export async function unfollowUser(followUserData: { username: string; token: string }) {
    const url = 'https://api.thez.ai/user/' + followUserData.username + '/unfollow'
    try {
        const response = await axios({
            method: 'post',
            url: url,
            headers: {
                'Authorization': `Bearer ${followUserData.token}` // Include the token in the Authorization header
            }
        })
        return response.data;
    } catch (error) {
        console.error("Failed to unfollow user", error);
    }
}