import {Avatar, Box, Center, Flex, Heading, Link, List, ListItem, Text} from "@chakra-ui/react";
import React from "react";
import {encodeTopicName} from "./Topic";

export default function Rightbar() {
    const trendingTopics = [
        {category: 'Health', name: 'Sleep', posts: 120},
        {category: 'Science', name: 'Psychology', posts: 45},
        {category: 'Business', name: 'Startups', posts: 90},
    ];

    // Sample data for Activity Section
    const activityFeed = [
        {
            name: 'Muqsit',
            username: 'muqsitnawaz',
            display: 'https://rexhttp.s3.amazonaws.com/users/muqsitnawaz/display.png?AWSAccessKeyId=AKIA55GARJJLLY6CM5V5&Signature=c33Llh8m6bNnCqZNSVo75HlbGfI%3D&Expires=1726567907',
            type: 'liked',
            what: 'What is the science behind goal setting?',
            link: '/post/1e70ea7b-1ec7-4677-9b18-1ed4408181a1'
        },
        {
            name: 'Bisma',
            username: 'bismanawaz',
            display: 'https://rexhttp.s3.amazonaws.com/users/bismanawaz/display.png?AWSAccessKeyId=AKIA55GARJJLLY6CM5V5&Signature=4auIIBdD8ddAOMchLcTROmnEgXA%3D&Expires=1726568187',
            type: 'shared',
            what: 'Where does hyper user growth come from?',
            link: '/post/1e70ea7b-1ec7-4677-9b18-1ed4408181a2'
        },
        {
            name: 'Muqsit',
            username: 'muqsitnawaz',
            display: 'https://rexhttp.s3.amazonaws.com/users/muqsitnawaz/display.png?AWSAccessKeyId=AKIA55GARJJLLY6CM5V5&Signature=c33Llh8m6bNnCqZNSVo75HlbGfI%3D&Expires=1726567907',
            type: 'followed',
            what: 'Bisma Nawaz',
            link: `/user/bismanawaz`
        },
    ];

    // TODO: Refresh Trending topics every 5 mins
    // TODO: Refresh Public Activity every 30 secs

    return (
        <Box
            w='100%'
            h='100%'
            color='white'
            position='sticky'
            top={0}
            overflowY='hidden'
            borderLeft="1px solid gray"
        >
            {/* All Users: Trending Section based on what they are asking*/}
            <Box>
                <Center>
                    <Heading
                        size="md"
                        mb={3}
                        p={6}
                        color="black"
                    >
                        Trending
                    </Heading>
                </Center>
                <List>
                    {trendingTopics.map((topic, index) => (
                        <Box
                            key={index}
                            _hover={{backgroundColor: "gray.50", borderColor: "gray.300", borderWidth: "1px"}}
                        >
                            <Link href={`/topic/${encodeTopicName(topic.name)}`}>
                                <ListItem p={3}>
                                    <Text fontSize="sm" color="gray.500">{topic.category}</Text>
                                    <Text
                                        fontWeight="bold"
                                        color="brand.100"
                                        _hover={{color: "brand.101"}}
                                    >
                                        {topic.name}
                                    </Text>
                                    <Text fontSize="sm" color="gray.400">{topic.posts} posts</Text>
                                </ListItem>
                            </Link>
                        </Box>
                    ))}
                </List>
            </Box>

            {/*All Users: What people are doing on the platform*/}
            <Box
                borderTop="1px solid gray"
            >
                <Center>
                    <Heading
                        size="md"
                        mb={3}
                        p={6}
                        color="black"
                    >
                        Activity
                    </Heading>
                </Center>
                <List>
                    {activityFeed.map((activity, index) => (
                        <Box
                            key={index}
                            _hover={{backgroundColor: "gray.50", borderColor: "gray.300", borderWidth: "1px"}}
                        >
                            <ListItem p={3}>
                                <Flex alignItems="center">
                                    <Avatar
                                        name={activity.name}
                                        src={activity.display}
                                        size="md"
                                    />

                                    <Box paddingLeft={2}>
                                        <Link href={`/user/${activity.username}`}>
                                            <Text
                                                as="span"
                                                fontSize="sm"
                                                fontWeight="bold"
                                                color="brand.100"
                                                _hover={{color: "brand.101"}}
                                            >
                                                {`${activity.name} `}

                                            </Text>
                                        </Link>
                                        <Text
                                            as="span"
                                            fontSize="sm"
                                            color="gray.400"
                                        >
                                            {`${activity.type} `}
                                        </Text>
                                        <Link href={activity.link}>
                                            <Text
                                                as="span"
                                                fontSize="sm"
                                                color="brand.100"
                                                _hover={{color: "brand.101"}}
                                            >
                                                {activity.what}
                                            </Text>
                                        </Link>
                                    </Box>
                                </Flex>
                            </ListItem>
                        </Box>
                    ))}
                </List>
            </Box>
        </Box>
    )
}