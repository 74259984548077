import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    Center,
    Flex,
    Icon,
    IconButton,
    Link,
    Textarea,
    useClipboard
} from "@chakra-ui/react";
import React, {useRef} from "react";
import {SmallCloseIcon} from "@chakra-ui/icons";
import {FaCopy} from "react-icons/fa";
import axios from "axios";


interface FeedbackPopupProps {
    postID: string;
    isOpen: boolean;
    onClose: () => void;
}

export function FeedbackPopup({postID, isOpen, onClose}: FeedbackPopupProps) {
    const [feedbackText, setFeedbackText] = React.useState<string>('');
    const cancelRef = useRef(null);  // Create a ref

    const handleSubmit = () => {
        if (feedbackText.length < 5) {
            return
        }

        axios({
            method: 'post',
            url: `https://api.thez.ai/post/${postID}/feedback`,
            data: {
                stars: 3,
                text: feedbackText
            }
        }).then(response => {
            console.log(response);
        }).catch(error => {
            console.error(error);
        });
        onClose();
    };

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            closeOnOverlayClick={false}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader
                        fontSize='md' fontWeight='bold'
                    >
                        How can we improve this response?
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        <Textarea
                            placeholder="Provide your feedback (5-150 characters)"
                            value={feedbackText}
                            onChange={(e) => setFeedbackText(e.target.value)}
                        />
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button onClick={onClose} variant="ghost">
                            Close
                        </Button>
                        <Button
                            colorScheme="teal"
                            onClick={handleSubmit}
                            isDisabled={feedbackText.length < 5}
                        >
                            Submit
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
}


interface SharePopupProps {
    postID: string;
    isOpen: boolean;
    onClose: () => void;
}

export function SharePopup({postID, isOpen, onClose}: SharePopupProps) {
    const hostname = window.location.hostname;
    const uniqueLink = `https://${hostname}/share/${postID}`;
    const {hasCopied, onCopy} = useClipboard(uniqueLink);

    const cancelRef = useRef(null);  // Create a ref

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            closeOnOverlayClick={false}
        >
            <AlertDialogOverlay>
                <AlertDialogContent margin="auto" marginTop="20vh" marginBottom="20vh">
                    <Box mt={2} ml={-2} width='100%' textAlign='right'>
                        <IconButton
                            variant='ghost'
                            aria-label='Close'
                            fontSize='20px'
                            textAlign='right'
                            icon={<SmallCloseIcon/>}
                            ref={cancelRef}
                            onClick={onClose}
                            _hover={{}}
                        />
                    </Box>

                    <AlertDialogBody>
                        <Center>
                            <Flex alignItems="center"> {/* Flexbox container for inline display */}
                                {/* Abbreviated link */}
                                <Link
                                    href={uniqueLink}
                                    isExternal
                                >
                                    {abbreviateLink(uniqueLink)}
                                </Link>

                                {/* IconButton */}
                                <IconButton
                                    ml={2}
                                    variant='ghost'
                                    aria-label={hasCopied ? 'Copied' : 'Copy'}
                                    fontSize="lg" // Same as text size
                                    icon={<Icon as={FaCopy} color={hasCopied ? 'black' : 'gray.600'}/>}
                                    onClick={onCopy}
                                />
                            </Flex>
                        </Center>
                    </AlertDialogBody>

                    <AlertDialogFooter fontSize="lg" fontWeight="bold">
                        {' '}
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

const abbreviateLink = (link: string) => {
    if (link.length > 30) {
        return `${link.slice(0, 13)}...${link.slice(-13)}`
    }
    return link
}