import axios from "axios";

export async function likePost(data: { postUUID: string }) {
    return updatePostAnon({postUUID: data.postUUID, action: "like"})
}

export async function dislikePost(data: { postUUID: string }) {
    return updatePostAnon({postUUID: data.postUUID, action: "dislike"})
}

export async function publicPost(data: { postUUID: string; token: string }) {
    return updatePost({postUUID: data.postUUID, action: "public", token: data.token})
}

export async function unpublicPost(data: { postUUID: string; token: string }) {
    return updatePost({postUUID: data.postUUID, action: "unpublic", token: data.token})
}

export async function savePost(data: { postUUID: string; token: string }) {
    return updatePost({postUUID: data.postUUID, action: "save", token: data.token})
}

export async function unsavePost(data: { postUUID: string; token: string }) {
    return updatePost({postUUID: data.postUUID, action: "unsave", token: data.token})
}

export async function sharePost(data: { postUUID: string; token: string }) {
    return updatePost({postUUID: data.postUUID, action: "share", token: data.token})
}

async function updatePostAnon(data: { postUUID: string; action: string }) {
    const url = 'https://api.thez.ai/post/' + data.postUUID + '/' + data.action

    console.log(data.action + " post " + data.postUUID + " using url " + url)

    try {
        const response = await axios({
            method: 'post',
            url: url,
        })
        return response.data;
    } catch (error) {
        console.error("Failed to " + data.action + " post", error);
    }
}

async function updatePost(data: { postUUID: string; action: string; token: string }) {
    const url = 'https://api.thez.ai/post/' + data.postUUID + '/' + data.action

    console.log(data.action + " post " + data.postUUID + " using url " + url)

    try {
        const response = await axios({
            method: 'post',
            url: url,
            headers: {
                'Authorization': `Bearer ${data.token}` // Include the token in the Authorization header
            }
        })
        return response.data;
    } catch (error) {
        console.error("Failed to " + data.action + " post", error);
    }
}