import {IContentCardProps, ICreator, IUser} from "./Provider";

export function mockCreator(username: string) {
    return mockCreators().get(username)!;
}

export function mockCreators() {
    return new Map<string, ICreator>([
        [
            "hubermanlab",
            {
                uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181c1",
                first_name: "Andrew",
                last_name: "Huberman",
                username: "hubermanlab",
                display: "https://rexhttp.s3.amazonaws.com/creators/hubermanlab/display.png?AWSAccessKeyId=AKIA55GARJJLLY6CM5V5&Signature=8EjvlyM%2B5Bc8b03IarQ8mxHLMGM%3D&Expires=1725016419",
                talks_about: ["Science", "Health", "Neuroscience"],
                bio: "Renowned neuroscientist at Stanford University, explores brain function, neural regeneration, and sleep science",
            }
        ],
        [
            "lexfridman",
            {
                uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181c2",
                first_name: "Lex",
                last_name: "Fridman",
                username: "lexfridman",
                display: "https://rexhttp.s3.amazonaws.com/creators/lexfridman/display.png?AWSAccessKeyId=AKIA55GARJJLLY6CM5V5&Signature=t%2FSeiv7%2FwqXZZ8PxVgTgkBT45n8%3D&Expires=1725016419",
                talks_about: ["Science", "Technology", "Philosophy"],
                bio: "AI researcher at MIT, explores deep learning, autonomous vehicles, and hosts a popular podcast on technology and philosophy.",
            }
        ],
        [
            "jordanbpeterson",
            {
                uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181c3",
                first_name: "Jordan",
                last_name: "Peterson",
                username: "jordanbpeterson",
                display: "https://rexhttp.s3.amazonaws.com/creators/jordanbpeterson/display.png?AWSAccessKeyId=AKIA55GARJJLLY6CM5V5&Signature=7Wq4NbAXOu3dDEuJniBZ5%2FR%2FVNI%3D&Expires=1725016419",
                talks_about: ["Psychology", "Philosophy", "Politics"],
                bio: "Clinical psychologist, University of Toronto professor, and author known for his influence on societal discourse.",
            }
        ],
        [
            "reidhoffman",
            {
                uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181c4",
                first_name: "Reid",
                last_name: "Hoffman",
                username: "reidhoffman",
                display: "https://rexhttp.s3.amazonaws.com/creators/reidhoffman/display.png?AWSAccessKeyId=AKIA55GARJJLLY6CM5V5&Signature=MQ7JTNFdSBGioN2Jnl8Xc4QYHY8%3D&Expires=1725016419",
                talks_about: ["Business", "Growth", "Startups"],
                bio: "LinkedIn co-founder, tech investor, author. Pioneer in networking & entrepreneurship. Building products that scale.",
            }
        ]
    ])
}

export function mockUser(username: string) {
    return mockUsers().get(username)!;
}

export function mockUsers() {
    return new Map<string, IUser>([
        [
            "muqsitnawaz",
            {
                name: "Muqsit Nawaz",
                username: "muqsitnawaz",
                display: "https://rexhttp.s3.amazonaws.com/users/muqsitnawaz/display.png?AWSAccessKeyId=AKIA55GARJJLLY6CM5V5&Signature=z27TVaL%2FI7vQJZXg303AtD98%2BNc%3D&Expires=1726124889"
            },
        ],
        [
            "tasbehanawaz",
            {
                name: "Tasbeha Nawaz",
                username: "tasbehanawaz",
                display: "https://rexhttp.s3.amazonaws.com/users/tasbehanawaz/display.png?AWSAccessKeyId=AKIA55GARJJLLY6CM5V5&Signature=GJmQQs79oxmO4zA6zSkXV5Rzm%2FA%3D&Expires=1726126271"
            },
        ]
        ,
        [
            "bismanawaz",
            {
                name: "Bisma Nawaz",
                username: "bismanawaz",
                display: "https://rexhttp.s3.amazonaws.com/users/bismanawaz/display.png?AWSAccessKeyId=AKIA55GARJJLLY6CM5V5&Signature=BLuyOdpZ4Tp%2B8KifOphxn6GifbQ%3D&Expires=1726126287"
            },
        ]
    ])
}

// TODO: Mock responses for two different creators
export function mockPosts() {
    const posts: IContentCardProps[] = [
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181a1",
            query: "What is the science behind goal setting?",
            response: "The science behind goal setting involves several key factors. First, it's important to have ownership over your goals. When you set your own goals, you feel more motivated and in control. Second, setting goals when you are in the same psychological and physiological state as when you will be pursuing them is crucial. This helps bridge the empathy gap between your present and future selves. Third, there is the goal gradient effect, which means that motivation tends to increase as you get closer to achieving your goal. To combat the lull in motivation during the middle of goal pursuit, it is recommended to shorten the time duration of your goals. Fourth, building in an emergency reserve or slack into your goal setting can be helpful. This means giving yourself some flexibility or \"get out of jail free cards to account for unforeseen circumstances. Lastly, the way we define our goals matters. Framing goals in terms of approach orientation, focusing on what we want to achieve, tends to be more motivating and measurable compared to avoidance orientation, which focuses on what we want to avoid. These insights are backed by high-quality research and can help improve our goal-setting strategies.",
            links: [
                "https://www.youtube.com/watch?v=X8Hw8zeCDTA&t=6521.699s",
                "https://www.youtube.com/watch?v=X8Hw8zeCDTA&t=8100.179s",
            ],
            tags: ["Neuroscience", "Psychology"],
            creator: mockCreator("hubermanlab"),
            user: mockUser("muqsitnawaz"),
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181a2",
            query: "Where does hyper user growth come from?",
            response: "Hyper user growth comes from a combination of factors. One key factor is the focus on virality and retention in the distribution strategy. It is important to bring in new users, but it is equally important to ensure that they stay and continue using the product. Dropbox, for example, learned this the hard way when they discovered that only 40% of their sign-ups were actually using the service. They conducted usability tests and made improvements to increase user engagement. Another factor is the power of rapid growth itself. Studies have shown that high-growth companies offer greater returns to shareholders and are more likely to achieve long-term success. Once a compan achieves rapid growth and becomes a market leader. it attracts top professionals and capital, further fueling its growth. Additionally, hyper user growth can be driven by leveraging existing networks, creating network effects, and utilizing distribution innovations. For example, Facebook combined the organic virality of a social network with the leverage of existing networks centered around college campuses to achieve massive scale. The compatibility and standards of Google's G Suite also contributed to its growth. Overall, hyper user growth comes from a combination of effective distribution strategies, rapid growth, leveraging existing networks, and creating network effects.",
            links: ["https://www.youtube.com/watch?v=TF_0V7mHpc0"],
            tags: ["Startups", "Innovation", "Scaling"],
            creator: mockCreator("reidhoffman"),
            user: mockUser("muqsitnawaz"),
            visibility: "internal",
            status: "unsaved"
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181a3",
            query: "How to treat your children during development years?",
            response: "During the development years, it is important to treat your children in a way that promotes their social development and psychological maturity. This can be achieved by setting clear rules and principles of discipline and punishment. By doing this, you create a secure and calm environment for your children, which helps them thrive in a potentially cold and unforgiving world. It is also important to have likes and dislikes when it comes to your children's behavior, as this allows you to judge what is suitable and unsuitable. Taking responsibility for their discipline and apologizing when you make mistakes shows them that you love them and want them to grow into well-behaved individuals. By teaching them desirable behaviors and helping them become socially desirable, you are giving them the opportunity, self-regard, and security they need to succeed in life.",
            links: ["https://www.youtube.com/watch?v=PTq6CYfikbg&t=1213.445", "12 Rules for Life: An Antidote to Chaos, RULE 1: Stand up straight with your shoulders back"],
            tags: ["Psychology", "Biology"],
            creator: mockCreator("jordanbpeterson"),
            user: mockUser("muqsitnawaz"),
            visibility: "private",
            status: "saved"
        },
        // Health
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181a4",
            query: "What are the benefits of a balanced diet?",
            response: "A balanced diet provides the essential nutrients your body needs to function correctly. It reduces the risk of chronic diseases like obesity, heart disease, and type 2 diabetes. A balanced diet ensures that you get adequate vitamins, minerals, and dietary fiber, which contribute to overall well-being.",
            links: ["https://www.youtube.com/watch?v=WTq1zwvfQYM"],
            tags: ["Health", "Nutrition"],
            creator: mockCreator("hubermanlab"),
            user: mockUser("tasbehanawaz"),
            visibility: "external",
            status: "saved"
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181a5",
            query: "How does exercise impact mental health?",
            response: "Exercise has a profound impact on mental health. It releases endorphins, which act as natural mood lifters. It can also help with anxiety and depression, improving mental clarity and focus.",
            links: ["https://www.youtube.com/watch?v=Ks-_Mh1QhMc"],
            tags: ["Health", "Mental Health"],
            creator: mockCreator("hubermanlab"),
            user: mockUser("tasbehanawaz")
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181a6",
            query: "Is organic food really better?",
            response: "Organic food is grown without synthetic pesticides and fertilizers. While it's often considered healthier, the scientific consensus on this is not entirely clear. However, organic farming practices can be better for the environment.",
            links: ["https://www.youtube.com/watch?v=8PmM6SUn7Es"],
            tags: ["Health", "Organic Food"],
            creator: mockCreator("hubermanlab"),
            user: mockUser("tasbehanawaz")
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181a7",
            query: "How do vaccines work?",
            response: "Vaccines work by stimulating the immune system to fight against specific diseases. They introduce a weakened or inactivated form of the pathogen, which triggers the body to produce antibodies, providing immunity.",
            links: ["https://www.youtube.com/watch?v=zBkVCpbNnkU"],
            tags: ["Health", "Vaccines"],
            creator: mockCreator("hubermanlab"),
            user: mockUser("tasbehanawaz")
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181a8",
            query: "What's the importance of hydration?",
            response: "Staying hydrated is crucial for health and well-being. Water is necessary for nearly every bodily function, including regulating temperature, transporting nutrients, and maintaining cell structure.",
            links: ["https://www.youtube.com/watch?v=WhmM8gknuBQ"],
            tags: ["Health", "Hydration"],
            creator: mockCreator("hubermanlab"),
            user: mockUser("bismanawaz")
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181a9",
            query: "How effective are mental health apps?",
            response: "Mental health apps can be a useful supplement to traditional therapy, offering resources for stress management, anxiety, and depression. However, they should not replace professional medical advice.",
            links: ["https://www.youtube.com/watch?v=Cl8ijPGEKO8"],
            tags: ["Health", "Mental Health"],
            creator: mockCreator("hubermanlab"),
            user: mockUser("tasbehanawaz")
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181b0",
            query: "Why is sleep important?",
            response: "Sleep is essential for physical and mental health. It's the time when the body repairs itself and the brain consolidates memories. Lack of sleep can lead to numerous health issues, including weakened immune system and increased stress.",
            links: ["https://www.youtube.com/watch?v=gedoSfZvBgE"],
            tags: ["Health", "Sleep"],
            creator: mockCreator("hubermanlab"),
            user: mockUser("tasbehanawaz")
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181b1",
            query: "Does intermittent fasting work?",
            response: "Intermittent fasting has shown promising results in weight loss and improving metabolic health. However, its effectiveness can vary from person to person, and it may not be suitable for everyone.",
            links: ["https://www.youtube.com/watch?v=3dHcT1-K-tw"],
            tags: ["Health", "Fasting"],
            creator: mockCreator("hubermanlab"),
            user: mockUser("bismanawaz")
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181b2",
            query: "What is herd immunity?",
            response: "Herd immunity occurs when a large percentage of a population becomes immune to a disease, reducing its spread. It can be achieved through vaccination or natural infection, though vaccination is the safer route.",
            links: ["https://www.youtube.com/watch?v=7oKlNW5FI4A"],
            tags: ["Health", "Immunology"],
            creator: mockCreator("hubermanlab"),
            user: mockUser("tasbehanawaz")
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181b3",
            query: "How do you manage chronic pain?",
            response: "Managing chronic pain often involves a multi-disciplinary approach, including medication, physical therapy, and sometimes surgical interventions. Lifestyle changes like diet and exercise can also play a role.",
            links: ["https://www.youtube.com/watch?v=C_3phB93rvI"],
            tags: ["Health", "Pain Management"],
            creator: mockCreator("hubermanlab"),
            user: mockUser("muqsitnawaz")
        },
        // Psychology
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181b4",
            query: "What is cognitive dissonance?",
            response: "Cognitive dissonance refers to the mental discomfort one feels when holding two or more contradictory beliefs or attitudes simultaneously. The theory, formulated by Leon Festinger, states that individuals strive for internal consistency, and when inconsistency (dissonance) arises, people are motivated to reduce it. This can be done by changing one's belief, acquiring new information, or minimizing the importance of the dissonance.",
            links: ["https://www.youtube.com/watch?v=ZnE3uyj9Grg"],
            tags: ["Psychology"],
            creator: mockCreator("jordanbpeterson"),
            user: mockUser("bismanawaz")
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181b5",
            query: "How do emotions affect decision-making?",
            response: "Emotions play a crucial role in decision-making. They can serve as rapid signals that guide our choices, particularly in complex and uncertain situations. For example, fear may prevent us from taking unnecessary risks, while happiness can encourage us to pursue opportunities. However, emotions can also cloud our judgment and lead to irrational decisions.",
            links: ["https://www.youtube.com/watch?v=dFs9WO2B8uI"],
            tags: ["Psychology"],
            creator: mockCreator("hubermanlab"),
            user: mockUser("tasbehanawaz")
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181b6",
            query: "What is the placebo effect?",
            response: "The placebo effect is a psychological phenomenon where a patient experiences real improvements in health after receiving a treatment with no therapeutic value, simply because they believe it will work. This highlights the power of belief and expectation in the healing process.",
            links: ["https://www.youtube.com/watch?v=QvbQnMvhQFw"],
            tags: ["Psychology"],
            creator: mockCreator("hubermanlab"),
            user: mockUser("bismanawaz")
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181b7",
            query: "How does stress affect the body?",
            response: "Stress triggers the body's 'fight or flight' response, releasing hormones like adrenaline and cortisol. While short-term stress can enhance performance, chronic stress can have detrimental effects, including weakening the immune system, causing digestive issues, and contributing to mental health problems like anxiety and depression.",
            links: ["https://www.youtube.com/watch?v=v-t1Z5-oPtU"],
            tags: ["Psychology"],
            creator: mockCreator("hubermanlab"),
            user: mockUser("muqsitnawaz")
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181b8",
            query: "What is mindfulness?",
            response: "Mindfulness is the practice of paying attention to the present moment in a non-judgmental manner. It has roots in Buddhist philosophy and has been adapted into various psychotherapy techniques. Mindfulness practices have been shown to reduce symptoms of anxiety, depression, and chronic pain.",
            links: ["https://www.youtube.com/watch?v=w6T02g5hnT4"],
            tags: ["Psychology"],
            creator: mockCreator("hubermanlab"),
            user: mockUser("tasbehanawaz")
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181b9",
            query: "How does social media affect mental health?",
            response: "Social media has been linked to both positive and negative effects on mental health. On one hand, it allows for social connectivity, but it has also been associated with increased levels of anxiety, depression, and loneliness, often exacerbated by comparison, cyberbullying, and the addictive nature of these platforms.",
            links: ["https://www.youtube.com/watch?v=Czg_9C7gw0o"],
            tags: ["Psychology"],
            creator: mockCreator("hubermanlab"),
            user: mockUser("bismanawaz")
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181c1",
            query: "What's the impact of nature on well-being?",
            response: "Exposure to nature has been shown to have various positive effects on mental well-being. It can reduce stress levels, improve mood, and enhance cognitive function. Some studies even suggest that spending time in natural settings can boost immunity and decrease the risk of chronic diseases.",
            links: ["https://www.youtube.com/watch?v=SrcMMyNeT5Y"],
            tags: ["Psychology"],
            creator: mockCreator("hubermanlab"),
            user: mockUser("tasbehanawaz")
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181c2",
            query: "Is multitasking effective?",
            response: "Research suggests that multitasking is generally not effective for complex tasks requiring focus and cognitive effort. Attempting to do multiple tasks at once can lead to errors and decreased productivity, as the brain is not truly capable of handling more than one high-level function at a time.",
            links: ["https://www.youtube.com/watch?v=3QetfnYgjRE"],
            creator: mockCreator("hubermanlab"),
            tags: ["Psychology"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181c3",
            query: "What causes procrastination?",
            response: "Procrastination is a complex behavior with multiple causes, including fear of failure, a desire for perfection, and lack of self-discipline. Some psychological theories suggest it might be a way to cope with uncomfortable emotions or to exert control.",
            links: ["https://www.youtube.com/watch?v=arj7oStGLkU"],
            creator: mockCreator("hubermanlab"),
            tags: ["Psychology"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181c4",
            query: "How do video games affect the brain?",
            response: "Video games can have both positive and negative effects on the brain. They can improve hand-eye coordination, problem-solving skills, and multi-tasking abilities. However, excessive gaming can lead to negative outcomes like addiction, social isolation, and increased aggression.",
            links: ["https://www.youtube.com/watch?v=OOsqkQytHOs"],
            creator: mockCreator("jordanbpeterson"),
            tags: ["Psychology"]
        },
        // Life
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181c5",
            query: "What is the key to happiness?",
            response: "The key to happiness is a complex combination of factors such as personal fulfillment, meaningful relationships, and a sense of purpose. Research shows that gratitude and mindfulness can also play a huge role in promoting happiness. Activities that induce a flow state can also contribute to overall well-being.",
            links: ["https://www.youtube.com/watch?v=8s3bdVxuFBs"],
            creator: mockCreator("jordanbpeterson"),
            tags: ["Life", "Psychology"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181c6",
            query: "How important is work-life balance?",
            response: "Work-life balance is crucial for both mental and physical health. Lack of balance can lead to burnout, stress, and various health issues. It's important to set boundaries and prioritize time for relaxation and family.",
            links: ["https://www.youtube.com/watch?v=lFFTJiLLn4s"],
            creator: mockCreator("lexfridman"),
            tags: ["Life", "Career"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181c7",
            query: "What makes a good leader?",
            response: "A good leader is someone who is empathetic, visionary, and able to inspire others. They should be able to communicate effectively and make decisions that are in the best interest of the team or organization.",
            links: ["https://www.youtube.com/watch?v=TPf3MbyfQMQ"],
            creator: mockCreator("lexfridman"),
            tags: ["Life", "Leadership"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181c8",
            query: "How do you cultivate creativity?",
            response: "Creativity can be cultivated by embracing failure, taking risks, and exposing yourself to different perspectives and experiences. Regular practice and challenging yourself are also key elements.",
            links: ["https://www.youtube.com/watch?v=J3Om3vknLl0"],
            creator: mockCreator("hubermanlab"),
            tags: ["Life", "Creativity"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181c9",
            query: "What is the value of mentorship?",
            response: "Mentorship provides invaluable perspective and guidance, aiding both personal and professional development. It offers networking opportunities and can be a mutually beneficial relationship.",
            links: ["https://www.youtube.com/watch?v=xzIZLqp9vpY"],
            creator: mockCreator("lexfridman"),
            tags: ["Life", "Career"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181d2",
            query: "What's the importance of lifelong learning?",
            response: "Lifelong learning keeps you adaptable, relevant in your field, and mentally active. It also opens doors to new opportunities and enriches your personal life.",
            links: ["https://www.youtube.com/watch?v=8Uy2Wx90OF4"],
            creator: mockCreator("lexfridman"),
            tags: ["Life", "Education"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181d3",
            query: "How do you maintain friendships?",
            response: "Maintaining friendships requires effort, open communication, and genuine interest in each other's lives. Quality time and mutual respect are crucial elements.",
            links: ["https://www.youtube.com/watch?v=N1Ea6hzR4mg"],
            creator: mockCreator("lexfridman"),
            tags: ["Life", "Relationships"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181d4",
            query: "What's the key to a successful relationship?",
            response: "Successful relationships are built on trust, open communication, and mutual respect. Both parties should be willing to compromise and invest in the relationship.",
            links: ["https://www.youtube.com/watch?v=ElNm5RXlPVI"],
            creator: mockCreator("jordanbpeterson"),
            tags: ["Life", "Relationships"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181d5",
            query: "Is a college degree necessary?",
            response: "A college degree is not a one-size-fits-all requirement. While it can open doors and provide a foundation of knowledge, many successful individuals have thrived without one. Skill acquisition and experience are increasingly valuable.",
            links: ["https://www.youtube.com/watch?v=5I0Kj8ZF3IM"],
            creator: mockCreator("jordanbpeterson"),
            tags: ["Life", "Education"]
        },
        // Startups
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181d6",
            query: "What's the most important factor in startup success?",
            response: "The most important factor in startup success is arguably the founding team. A committed, versatile team that complements each other's skills can overcome many obstacles. Having a clear vision, understanding the market, and being flexible are also essential traits.",
            links: ["https://www.youtube.com/watch?v=ClT4mQQ3K_o"],
            creator: mockCreator("reidhoffman"),
            tags: ["Startups", "Success"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181d7",
            query: "How do you validate a business idea?",
            response: "Validating a business idea often starts with market research to identify a need. Then, building a minimum viable product (MVP) and gathering user feedback helps you iteratively improve the product.",
            links: ["https://www.youtube.com/watch?v=YP5tBh_xp04"],
            creator: mockCreator("reidhoffman"),
            tags: ["Startups", "Business"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181d8",
            query: "When should a startup consider pivoting?",
            response: "A startup should consider pivoting when it becomes clear that the current approach is not leading to sustainable growth or profitability. Constant negative feedback, dwindling resources, or poor KPIs are indicators.",
            links: ["https://www.youtube.com/watch?v=qlEYaLU_rBM"],
            creator: mockCreator("reidhoffman"),
            tags: ["Startups", "Strategy"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181d9",
            query: "How do you measure product-market fit?",
            response: "Product-market fit is typically measured through customer feedback and key performance indicators like churn rate, customer lifetime value, and net promoter score (NPS). High retention and organic growth are good signs.",
            links: ["https://www.youtube.com/watch?v=3cIbZ4ZiHvU"],
            creator: mockCreator("reidhoffman"),
            tags: ["Startups", "Product"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181e1",
            query: "What are the key metrics for startup success?",
            response: "Key metrics can vary by industry but often include customer acquisition cost (CAC), customer lifetime value (CLV), retention rate, monthly recurring revenue (MRR), and growth rate.",
            links: ["https://www.youtube.com/watch?v=ukyIz8vKwnw"],
            creator: mockCreator("reidhoffman"),
            tags: ["Startups", "Metrics"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181e2",
            query: "How do you scale a business?",
            response: "Scaling a business involves optimizing and standardizing operations for growth. It often includes systematizing key processes, expanding to new markets, and growing the team.",
            links: ["https://www.youtube.com/watch?v=kb3x5-VmgAM"],
            creator: mockCreator("reidhoffman"),
            tags: ["Startups", "Scaling"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181e3",
            query: "What is bootstrapping?",
            response: "Bootstrapping involves starting and growing a business without external investment. It requires a focus on profitability, organic growth, and often involves the founders putting in their own money.",
            links: ["https://www.youtube.com/watch?v=UoBBhRgW5O0"],
            creator: mockCreator("reidhoffman"),
            tags: ["Startups", "Funding"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181e4",
            query: "What's the role of a startup incubator?",
            response: "A startup incubator provides early-stage companies with resources, mentorship, office space, and sometimes funding to help them grow and succeed.",
            links: ["https://www.youtube.com/watch?v=tzha_jylvRs"],
            creator: mockCreator("reidhoffman"),
            tags: ["Startups", "Incubator"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181e5",
            query: "When should you seek venture capital?",
            response: "Venture capital is suitable for startups aiming for rapid growth and willing to give up equity. When the business model is proven and scalable, it might be the right time to seek venture capital.",
            links: ["https://www.youtube.com/watch?v=8OCjwBkMJ_E"],
            creator: mockCreator("reidhoffman"),
            tags: ["Startups", "Venture Capital"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181e6",
            query: "How do you exit a startup?",
            response: "Exiting a startup typically occurs through a sale, merger, or initial public offering (IPO). Founders and investors aim to maximize their returns through these exit strategies.",
            links: ["https://www.youtube.com/watch?v=mY8uBF8fzvg"],
            creator: mockCreator("reidhoffman"),
            tags: ["Startups", "Exit Strategy"]
        },
        // Technology
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181e7",
            query: "How is AI impacting our daily lives?",
            response: "AI is increasingly integrated into our daily lives, affecting various sectors such as healthcare, transportation, and commerce. AI algorithms help doctors with diagnostics, enable self-driving cars, and power recommendation engines on e-commerce sites.",
            links: ["https://www.youtube.com/watch?v=AI_impact"],
            creator: mockCreator("lexfridman"),
            tags: ["Technology", "AI"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181e8",
            query: "What are the ethical implications of data mining?",
            response: "Data mining poses several ethical issues like privacy invasion, data security, and potential misuse of information. These concerns necessitate stringent regulations and ethical guidelines.",
            links: ["https://www.youtube.com/watch?v=data_mining_ethics"],
            creator: mockCreator("lexfridman"),
            tags: ["Technology", "Ethics"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181e9",
            query: "What is quantum computing?",
            response: "Quantum computing leverages quantum bits (qubits) instead of binary bits, enabling far more complex computations to be executed exponentially faster than traditional computing methods.",
            links: ["https://www.youtube.com/watch?v=quantum_intro"],
            creator: mockCreator("reidhoffman"),
            tags: ["Technology", "Quantum Computing"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181f1",
            query: "How secure is cloud storage?",
            response: "Cloud storage varies in security depending on the provider. While most reputable providers use robust encryption methods, no system is entirely immune from security threats.",
            links: ["https://www.youtube.com/watch?v=cloud_security"],
            creator: mockCreator("reidhoffman"),
            tags: ["Technology", "Cloud Storage"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181f2",
            query: "What are the basics of cybersecurity?",
            response: "Cybersecurity basics include understanding how to protect systems, networks, and data from digital attacks. Key measures include using strong passwords, keeping software updated, and implementing firewalls.",
            links: ["https://www.youtube.com/watch?v=cybersecurity_basics"],
            creator: mockCreator("lexfridman"),
            tags: ["Technology", "Cybersecurity"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181f3",
            query: "How is 5G different from 4G?",
            response: "5G offers substantially faster data download and upload speeds compared to 4G. It also has lower latency, which is crucial for applications like self-driving cars and remote surgery.",
            links: ["https://www.youtube.com/watch?v=5G_vs_4G"],
            creator: mockCreator("lexfridman"),
            tags: ["Technology", "Networking"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181f4",
            query: "What are the applications of IoT?",
            response: "IoT applications range from smart homes to industrial automation. Devices are interconnected, allowing for improved efficiency and data collection.",
            links: ["https://www.youtube.com/watch?v=IoT_applications"],
            creator: mockCreator("lexfridman"),
            tags: ["Technology", "IoT"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181f5",
            query: "How does blockchain work?",
            response: "Blockchain is a decentralized database that is secure and transparent. Each block contains data and is connected to previous and subsequent blocks, making tampering difficult.",
            links: ["https://www.youtube.com/watch?v=blockchain_intro"],
            creator: mockCreator("lexfridman"),
            tags: ["Technology", "Blockchain"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181f6",
            query: "What is augmented reality?",
            response: "Augmented reality (AR) overlays digital information or objects in the real world, usually via a smartphone or AR glasses. It has applications in gaming, education, and industry.",
            links: ["https://www.youtube.com/watch?v=AR_intro"],
            creator: mockCreator("lexfridman"),
            tags: ["Technology", "AR"]
        },
        {
            post_uuid: "1e70ea7b-1ec7-4677-9b18-1ed4408181f7",
            query: "What are the challenges of remote work technology?",
            response: "Challenges include ensuring data security, maintaining team communication, and providing reliable access to company resources. Technology like VPNs and collaboration tools can help mitigate these issues.",
            links: ["https://www.youtube.com/watch?v=remote_work_challenges"],
            creator: mockCreator("jordanbpeterson"),
            tags: ["Technology", "Remote Work"]
        },
    ]
    return posts
}

export function mockSearchResponses() {
    const posts = mockPosts();
    return posts.map((post) => {
        return {
            ...post,
            query: undefined,
        };
    })
}

export function mockUserSavedPosts(username: string) {
    const posts = mockPosts();
    return posts.filter((post) => post.user?.username === username);
}

export function mockUserFollowings() {
    return [
        {
            name: "Bisma Nawaz",
            username: "bismanawaz",
            display: "https://rexhttp.s3.amazonaws.com/users/bismanawaz/display.png?AWSAccessKeyId=AKIA55GARJJLLY6CM5V5&Signature=4auIIBdD8ddAOMchLcTROmnEgXA%3D&Expires=1726568187"
        },
        {
            name: "Tasbeha Nawaz",
            username: "tasbehanawaz",
            display: "https://rexhttp.s3.amazonaws.com/users/tasbehanawaz/display.png?AWSAccessKeyId=AKIA55GARJJLLY6CM5V5&Signature=G4jVFHzCwbvgCp6SQPIzWDPLlh0%3D&Expires=1726607887"
        }
    ]
}

export function mockUserSubscriptions() {
    return [
        {
            name: "Andrew Huberman",
            username: "hubermanlab",
            display: "https://rexhttp.s3.amazonaws.com/creators/hubermanlab/display.png?AWSAccessKeyId=AKIA55GARJJLLY6CM5V5&Signature=AUi6yuuAsaD8rBfd0p0jfHIh834%3D&Expires=1726607503"
        },
        {
            name: "Reid Hoffman",
            username: "reidhoffman",
            display: "https://rexhttp.s3.amazonaws.com/creators/reidhoffman/display.png?AWSAccessKeyId=AKIA55GARJJLLY6CM5V5&Signature=hrLx7HO8B7hpW9nwOTp%2BhvshAE8%3D&Expires=1726607528"
        }
    ]
}

export function mockExplore() {
    return [
        {
            name: "Andrew Huberman",
            username: "hubermanlab",
            display: "https://rexhttp.s3.amazonaws.com/creators/hubermanlab/display.png?AWSAccessKeyId=AKIA55GARJJLLY6CM5V5&Signature=AUi6yuuAsaD8rBfd0p0jfHIh834%3D&Expires=1726607503",
            creator: true
        },
        {
            name: "Reid Hoffman",
            username: "reidhoffman",
            display: "https://rexhttp.s3.amazonaws.com/creators/reidhoffman/display.png?AWSAccessKeyId=AKIA55GARJJLLY6CM5V5&Signature=hrLx7HO8B7hpW9nwOTp%2BhvshAE8%3D&Expires=1726607528",
            creator: true
        },
        {
            name: "Bisma Nawaz",
            username: "bismanawaz",
            display: "https://rexhttp.s3.amazonaws.com/users/bismanawaz/display.png?AWSAccessKeyId=AKIA55GARJJLLY6CM5V5&Signature=4auIIBdD8ddAOMchLcTROmnEgXA%3D&Expires=1726568187",
            creator: false
        },
        {
            name: "Tasbeha Nawaz",
            username: "tasbehanawaz",
            display: "https://rexhttp.s3.amazonaws.com/users/tasbehanawaz/display.png?AWSAccessKeyId=AKIA55GARJJLLY6CM5V5&Signature=G4jVFHzCwbvgCp6SQPIzWDPLlh0%3D&Expires=1726607887",
            creator: false
        }
    ]
}