import React, {createContext, ReactNode, useEffect, useState} from "react"
import Cookies from "js-cookie"

export type IChunkable = ICreator | IPlatform;

export interface IZUser {
    name: string
    username: string
    display: string
}

export interface IUser {
    name: string
    username: string
    display: string
    token?: string
    bio?: string
    interests?: string[]
    following?: string[]
    following_data?: IZUser[]
    followers?: string[]
    followers_data?: IZUser[]
    subscriptions?: string[]
    subscriptions_data?: IZUser[]
    connected?: boolean
}

export interface MaybeIUser {
    user: IUser | undefined
}

export interface IUserContext {
    currentUser: IUser
    setCurrentUser: React.Dispatch<React.SetStateAction<IUser>>
}

// TODO: Learn when the value of UserContext is cleared
export const UserContext = createContext<IUserContext | undefined>(undefined)

export function UserProvider({children}: {
    children: ReactNode
}) {
    const initialUserState = Cookies.get('user') ? JSON.parse(Cookies.get('user') || '') : {username: '', token: ''}
    const [currentUser, setCurrentUser] = useState<IUser>(initialUserState)

    useEffect(() => {
        Cookies.set('user', JSON.stringify(currentUser))
    }, [currentUser])

    return (
        <UserContext.Provider value={{currentUser, setCurrentUser}}>
            {children}
        </UserContext.Provider>
    )
}

export interface IZCreator {
    name: string
    username: string
    display: string
    talks_about: string[]
}

export interface ICreator {
    uuid: string
    first_name: string
    last_name: string
    username: string
    display: string
    talks_about?: string[]
    bio?: string
    connected?: boolean
}

export interface MaybeICreator {
    creator: ICreator | undefined
}

export interface ICreatorsContext {
    selectedCreators: ICreator[]
    setSelectedCreators: React.Dispatch<React.SetStateAction<ICreator[]>>
}

export const CreatorsContext = createContext<ICreatorsContext | undefined>(undefined)

export function CreatorsProvider({children}: {
    children: ReactNode
}) {
    const initState = Cookies.get('creators') ? JSON.parse(Cookies.get('creators') || '') : []
    const [selectedCreators, setSelectedCreators] = useState<ICreator[]>(initState)

    useEffect(() => {
        Cookies.set('creators', JSON.stringify(selectedCreators))
    }, [selectedCreators])

    return (
        <CreatorsContext.Provider value={{selectedCreators, setSelectedCreators}}>
            {children}
        </CreatorsContext.Provider>
    )
}

export interface ICreatorContents {
    platform: string
    profile_ext_id: string
    profile_ext_username: string
    links: string[]
    titles: string[]
}

export interface IPlatform {
    uuid: string
    name: string
    display: string
}

export interface MaybeIPlatform {
    creator: IPlatform | undefined
}

export interface IPlatformsContext {
    selectedPlatforms: IPlatform[]
    setSelectedPlatforms: React.Dispatch<React.SetStateAction<IPlatform[]>>
}

export const PlatformsContext = createContext<IPlatformsContext | undefined>(undefined)

export function PlatformsProvider({children}: {
    children: ReactNode
}) {
    const initState = Cookies.get('platforms') ? JSON.parse(Cookies.get('platforms') || '') : []
    const [selectedPlatforms, setSelectedPlatforms] = useState<IPlatform[]>(initState)

    useEffect(() => {
        Cookies.set('platforms', JSON.stringify(selectedPlatforms))
    }, [selectedPlatforms])

    return (
        <PlatformsContext.Provider value={{selectedPlatforms, setSelectedPlatforms}}>
            {children}
        </PlatformsContext.Provider>
    )
}

export interface IContentCardProps {
    post_uuid: string
    query?: string
    response: string
    links: string[]
    tags: string[]
    creator: ICreator
    user?: IUser
    visibility?: string
    status?: string
}

export interface IExtContentCardProps {
    platform: string
    links: string[]
    titles: string[]
}