import React, {useContext, useEffect} from 'react'
import {Box, Grid, useBreakpointValue} from '@chakra-ui/react'
import Leftbar from './Leftbar'
import Rightbar from './Rightbar'
import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import {UserContext} from "./Provider"
import {Helmet} from 'react-helmet-async'

export default function Layout() {
    const isMobile = useBreakpointValue({base: true, md: true, lg: true, xl: false});
    const userContext = useContext(UserContext)
    const navigate = useNavigate()
    const location = useLocation()

    // Navigate to / if user is not logged in
    useEffect(() => {
        // Don't redirect if the user is on the share page
        if (location.pathname.startsWith("/share/")) {
            if (userContext?.currentUser?.token) {
                navigate('/view/' + location.pathname.split("/share/")[1])
            }
            return
        }

        if (!location.pathname.startsWith("/try")) {
            if (!userContext?.currentUser?.token) {
                navigate('/')
            }
        }
    }, [userContext, navigate])


    const noLayout = location.pathname === '/' || location.pathname.startsWith("/share/")
    if (noLayout) {
        console.log("window.location.href", window.location.href)

        return (
            <Box>
                <OpenGraphTags/>

                <Outlet/>
            </Box>
        )
    }

    const emptyLayout = location.pathname.startsWith("/try")
    if (emptyLayout) {
        return (
            <Box h="100vh">
                <OpenGraphTags/>

                {/*Render the outlet*/}
                {isMobile ?
                    <Box h="100%"
                         key={location.pathname}
                         borderRadius="md"
                    >
                        <Outlet/>
                    </Box> : <Grid templateColumns='0.2fr 0.6fr 0.2fr' h="100%">
                        <Box h="100%" borderRadius="md" overflowY='hidden'>
                        </Box>

                        {/*Render the outlet*/}
                        <Box h="100%"
                             key={location.pathname}
                             borderLeft="1px solid gray"
                             borderRight="1px solid gray"
                        >
                            <Outlet/>
                        </Box>

                        <Box h="100%" borderRadius="md">
                        </Box>
                    </Grid>}
            </Box>
        )
    }

    return (
        <Box h="100vh">
            <OpenGraphTags/>

            <Grid templateColumns='0.2fr 0.6fr 0.2fr' h="100%">
                <Box h="100%" borderRadius="md" overflowY='hidden'>
                    <Leftbar/>
                </Box>

                {/*Render the outlet*/}
                <Box h="100%"
                     key={location.pathname}
                     borderRadius="md"
                >
                    <Outlet/>
                </Box>

                <Box h="100%" borderRadius="md">
                    <Rightbar/>
                </Box>
            </Grid>
        </Box>
    )
}

function OpenGraphTags() {
    const location = useLocation();
    const openGraphTags = {
        title: "Z - The Last Content Platform You'll Ever Need!",
        description: "The Last Content Platform You'll Ever Need!",
        image: 'https://www.alpha.thez.ai/bg/bg3.png',
        url: window.location.href,
    };

    // List of paths where default OpenGraphTags should not be applied
    const pathsWithCustomTags = ['/try', '/creator', '/post']; // Add more paths as needed

    // Check if the current path matches any of the paths with custom tags
    console.log("location.pathname", location.pathname)
    const shouldSkipDefaultTags = pathsWithCustomTags.some(path => location.pathname.startsWith(path));

    if (shouldSkipDefaultTags) {
        return null; // or return <></> for an empty fragment
    }

    return (
        <Helmet>
            <meta property="og:title" content={openGraphTags.title}/>
            <meta property="og:description" content={openGraphTags.description}/>
            <meta property="og:image" content={openGraphTags.image}/>
            <meta property="og:url" content={openGraphTags.url}/>
        </Helmet>
    );
}