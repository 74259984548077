import {pickRandom} from "./Core";
import {Box, Button, Center, Heading, IconButton, Link, Text, VStack} from "@chakra-ui/react";
import {AddIcon, CloseIcon} from "@chakra-ui/icons";
import React, {useContext} from "react";
import {UserContext} from "./Provider";
import {useParams} from "react-router-dom";
import {mockPosts} from "./Mock";
import {ContentCard} from "./Card";
import axios from "axios";

export function encodeTopicName(topic: string): string {
    return topic.replace(" ", "-").toLowerCase()
}

export function decodeTopicName(encoded: string | undefined): string {
    if (!encoded) {
        return ""
    }

    const tokens = encoded.split("-")
    let result = ""
    for (let i = 0; i < tokens.length - 1; i++) {
        result += tokens[i].charAt(0).toUpperCase() + tokens[i].slice(1) + " "
    }
    result += tokens[tokens.length - 1].charAt(0).toUpperCase() + tokens[tokens.length - 1].slice(1)
    return result
}

const subscribeTopic = async (topicData: { topic: string; token: string }) => {
    const url = 'https://api.thez.ai/topic/' + topicData.topic + '/subscribe'
    try {
        const response = await axios({
            method: 'post',
            url: url,
            headers: {
                'Authorization': `Bearer ${topicData.token}` // Include the token in the Authorization header
            }
        })

        return response.data;
    } catch (error) {
        console.error("Failed to subscribe to topic", error);
    }
}

const unsubscribeTopic = async (topicData: { topic: string; token: string }) => {
    const url = 'https://api.thez.ai/topic/' + topicData.topic + '/unsubscribe'
    try {
        const response = await axios({
            method: 'post',
            url: url,
            headers: {
                'Authorization': `Bearer ${topicData.token}` // Include the token in the Authorization header
            }
        })

        return response.data;
    } catch (error) {
        console.error("Failed to unsubscribe to topic", error);
    }
}

export interface TopicButtonProps {
    index: number
    topic: string
}

export function TopicButton({index, topic}: TopicButtonProps) {
    const {currentUser, setCurrentUser} = useContext(UserContext)!;

    console.log("Interests: " + currentUser?.interests + " Topic: " + topic)

    const handleRemoveTopic = () => {
        unsubscribeTopic({topic: topic, token: currentUser?.token || ""}).then((response) => {
            console.log("unsubscribeTopic response: " + response)

            const updatedCurrentUser = {...currentUser};
            if (currentUser?.interests) {
                updatedCurrentUser.interests = currentUser.interests.filter(item => item !== topic)
            }
            setCurrentUser(updatedCurrentUser)
        })
    }

    const handleAddTopic = () => {
        subscribeTopic({topic: topic, token: currentUser?.token || ""}).then((response) => {
            console.log("subscribeTopic response: " + response)

            const updatedCurrentUser = {...currentUser};
            if (currentUser?.interests) {
                updatedCurrentUser.interests = currentUser.interests.concat(topic)
            }
            setCurrentUser(updatedCurrentUser)
        })
    }

    return (
        <Button
            key={index}
            size="sm"
            variant="solid"
            colorScheme={pickRandom(['red', 'green', 'blue', 'purple', 'pink', 'orange', 'yellow'])}
            marginRight={2}
            fontWeight="bold"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
        >
            <VStack
                spacing={0}
                alignItems="flex-start"
            >
                {currentUser?.interests?.includes(topic) ? (
                    <IconButton
                        icon={<CloseIcon/>}
                        aria-label="Remove Topic"
                        size="xs"
                        variant='ghost'
                        color='white'
                        sx={{
                            outline: "none",
                            border: "none",
                            "&:hover": {
                                background: "transparent", // Set the background to transparent on hover
                                boxShadow: "none", // Remove any box shadow on hover
                            },
                        }}
                        onClick={handleRemoveTopic}
                    />
                ) : (
                    <IconButton
                        icon={<AddIcon/>}
                        aria-label="Add Topic"
                        size="xs"
                        variant='ghost'
                        color='white'
                        sx={{
                            outline: "none",
                            border: "none",
                            "&:hover": {
                                background: "transparent", // Set the background to transparent on hover
                                boxShadow: "none", // Remove any box shadow on hover
                            },
                        }}
                        onClick={handleAddTopic}
                    />
                )}
            </VStack>
            {/*TODO: Add a divider between Icon Button and Text*/}
            <Link href={`/topic/${encodeTopicName(topic)}`}>
                <Text
                    pl={2}
                >
                    {topic}
                </Text>
            </Link>
        </Button>
    )
}

export default function Topic() {
    const {topic} = useParams()

    const topicName = decodeTopicName(topic)

    // TODO: Fetch the following posts from the Database
    const topicPosts = mockPosts().filter(post => post.tags.includes(topicName))

    return (
        <Box>
            <Box
                my={10}
            >
                <Center>
                    <Heading as='h3' size='lg'>
                        {decodeTopicName(topic)}
                    </Heading>
                </Center>
            </Box>

            <Box
                maxW="100%"
            >
                <Box
                    m={4}
                >
                    {topicPosts.map((topicPost, index) => (
                        <ContentCard
                            post_uuid={topicPost.post_uuid}
                            query={topicPost.query}
                            response={topicPost.response}
                            links={topicPost.links}
                            creator={topicPost.creator}
                            tags={topicPost.tags}
                        />
                    ))}
                </Box>
            </Box>
        </Box>
    )
}