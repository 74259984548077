import {Box, Flex} from "@chakra-ui/react";
import {useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {UserContext} from "./Provider";
import {mockPosts} from "./Mock";
import {ContentCard} from "./Card";

export default function Post() {
    const userContext = useContext(UserContext)
    const navigate = useNavigate()

    const {post_uuid} = useParams()
    const mustPostUUID = post_uuid!

    useEffect(() => {
        if (userContext?.currentUser?.token) {
            navigate('/view/' + mustPostUUID)
        } else {
            navigate('/share/' + mustPostUUID)
        }
    }, [mustPostUUID, userContext, navigate])

    return (
        <>
        </>
    )
}

export function View() {
    const currentUser = useContext(UserContext)?.currentUser
    const [fetchErr, setFetchErr] = useState<string | null>(null);

    const {post_uuid} = useParams()
    const mustPostUUID = post_uuid!
    const mockedPost = getPost(mustPostUUID)

    useEffect(() => {
        const fetchUser = async () => {
            const url = 'https://api.thez.ai/post/' + post_uuid!
            const token = currentUser?.token || ""

            try {
                const response = await axios({
                    method: 'get',
                    url: url,
                    headers: {
                        'Authorization': `Bearer ${token}` // Include the token in the Authorization header
                    }
                })
            } catch (error) {
                setFetchErr("Failed to fetch post.");
                console.error("Failed to fetch post", error);
            }
        };

        (async () => {
            await fetchUser();
        })();
    }, [currentUser, mustPostUUID]);

    return (
        <Box width="100%" height="100vh">
            <Flex justifyContent="center" alignItems="center" height="100%">
                <Box
                    m={4}
                >
                    <ContentCard
                        post_uuid={mockedPost.post_uuid}
                        query={mockedPost.query}
                        response={mockedPost.response}
                        links={mockedPost.links}
                        creator={mockedPost.creator}
                        tags={mockedPost.tags}
                    />
                </Box>
            </Flex>
        </Box>
    )
}

export function Share() {
    const {post_uuid} = useParams()

    const mockedPost = getPost(post_uuid!)

    return (
        <Box width="100%" height="100vh">
            <Flex justifyContent="center" alignItems="center" height="100%">
                <Box
                    width="60%"
                >
                    <ContentCard
                        post_uuid={mockedPost.post_uuid}
                        query={mockedPost.query}
                        response={mockedPost.response}
                        links={mockedPost.links}
                        creator={mockedPost.creator}
                        tags={mockedPost.tags}
                    />
                </Box>
            </Flex>
        </Box>
    )
}

function getPost(postUUID: string) {
    const mockedPosts = mockPosts()
    const maybeMockedPost = mockedPosts.find(post => post.post_uuid === postUUID);
    return maybeMockedPost!
}