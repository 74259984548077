import {InstagramEmbed} from "react-social-media-embed";
import YouTube from "react-youtube";
import {Heading, Image, VStack} from "@chakra-ui/react";

interface EmbedProps {
    platform?: string
    title?: string
    URL: string
    justify: string
    autoplay?: boolean
}

export function EmbedPost({platform, title, URL, justify}: EmbedProps) {
    console.log(`Rendering ${platform}: ${URL}`);

    switch (platform) {
        case "youtube":
            return <EmbedYouTube URL={URL} justify={justify}/>;
        case "instagram":
            return <EmbedInstagram URL={URL} justify={justify}/>;
        case "libgen":
            return <EmbedLibgen title={title} URL={URL} justify={justify}/>;
        default:
            return null; // Or render a default component for unknown platforms
    }
}

// TODO: Make the following function responsive for mobile screens
export function EmbedYouTube({URL, justify, autoplay}: EmbedProps) {
    console.log("Rendering YouTube: " + URL);

    const videoId = URL.split('v=')[1]?.split('&')[0];
    const timestampString = URL.split('&t=')[1];
    const startTime = timestampString ? parseInt(timestampString.replace('s', ''), 10) : 0;

    const opts = {
        width: '640',
        height: '480',
        autoplay: autoplay,
        playerVars: {
            autoplay: 0,
            start: startTime,
        },
    };

    return (
        <div style={{display: 'flex', justifyContent: justify, maxWidth: '100%'}}>
            <YouTube videoId={videoId} opts={opts}/>
        </div>
    );
}

export function EmbedInstagram({URL, justify}: EmbedProps) {
    console.log("Rendering Instagram: " + URL)

    let postURL: string = URL!;

    return (
        <div style={{display: 'flex', justifyContent: justify, maxWidth: '100%'}}>
            <InstagramEmbed url={postURL} captioned={false}/>
        </div>
    )
}

export function EmbedLibgen({title, URL, justify}: EmbedProps) {
    console.log(`Rendering LibGen: Title=${title} URL=${URL}`);

    let postTitle: string = title!
    let postURL: string = URL!;

    return (
        <div style={{display: 'flex', justifyContent: justify, maxWidth: '100%'}}>
            <VStack>
                <Heading as='h4' size='md'>
                    {postTitle}
                </Heading>

                <Image
                    src={postURL}
                    alt="Book Cover"
                    maxW="100%"
                    pt={2}
                />
            </VStack>
        </div>
    )
}