import React, {useContext, useEffect, useState} from "react";
import {Box, Button, Center, Flex, Input, InputGroup, InputRightElement, Select, VStack} from "@chakra-ui/react";
import axios from "axios";
import {IContentCardProps, IZCreator, UserContext} from "./Provider";
import {ContentCard} from "./Card";


function SearchInput({onSearch}: { onSearch: (username: string, query: string) => void }) {
    const [creators, setCreators] = React.useState<IZCreator[]>([]);
    const [selectedCreator, setSelectedCreator] = React.useState<string>("");
    const [query, setQuery] = React.useState('');
    const [fetchErr, setFetchErr] = useState<string | null>(null);

    const currentUser = useContext(UserContext)?.currentUser

    // const creator = mockCreator(username)
    useEffect(() => {
        const fetchCreator = async () => {
            const token = currentUser?.token || ""

            try {
                const response = await axios({
                    method: 'get',
                    url: 'https://api.thez.ai/creators/list',
                    headers: {
                        'Authorization': `Bearer ${token}` // Include the token in the Authorization header
                    }
                })

                if (response.data === 0) {
                    setFetchErr("No creator returned from the server");
                } else {
                    setCreators(response.data as IZCreator[]);
                    console.log("Fetched " + creators.length + " creators")
                }
            } catch (error) {
                setFetchErr("Failed to fetch creators.");
                console.error("Failed to fetch creators", error);
            }
        };

        (async () => {
            await fetchCreator();
        })();
    }, [currentUser]);


    // TODO: Move the following set of codes to Global React Context

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const username = creators.find(creator => creator.name === event.target.value)?.username;
        console.log(username);
        setSelectedCreator(username!);
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.currentTarget.value);
    }

    return (
        <Box
            width="100%"
        >
            <Flex
                p={4}
                borderBottom="1px solid gray"
            >
                <InputGroup size={'lg'} width='100%'>
                    <Select
                        placeholder="Select option"
                        onChange={handleSelectChange}
                        maxW='20%'
                    >
                        {creators.map((creator, index) => (
                            <option key={index} value={creator.name}>
                                {creator.name}
                            </option>
                        ))}
                    </Select>

                    <Input
                        placeholder="What do you want to know?"
                        value={query}
                        onChange={handleInputChange}
                    />

                    <InputRightElement paddingRight={7}>
                        <Button
                            size='md'
                            paddingX={8}
                            onClick={() => onSearch(selectedCreator, query)}
                        >
                            VIBIT
                        </Button>
                    </InputRightElement>
                </InputGroup>
            </Flex>
        </Box>
    )
}

interface SearchOutputProps {
    searchResponses: IContentCardProps[];
}

function SearchOutput({searchResponses}: SearchOutputProps) {
    return (
        <Box
            margin={4}
        >
            {searchResponses?.map((searchResponse) => ContentCard(searchResponse))}
        </Box>
    )
}

export default function Home() {
    const [searchResponses, setSearchResponses] = React.useState<IContentCardProps[]>([]);
    const currentUser = useContext(UserContext)?.currentUser

    const getSearchResults = (
        creatorUsername: string,
        searchQuery: string
    ) => {
        const token = currentUser?.token || ""

        axios({
            method: 'get',
            url: `https://api.thez.ai/creator/${creatorUsername}/search`,
            params: {
                query: searchQuery  // Sending searchQuery as a query parameter
            },
            headers: {
                'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
            }
        }).then(response => {
            setSearchResponses(response.data as IContentCardProps[]); // Update the state with the response data
            console.log(response);
        }).catch(error => {
            console.error(error);
        });
    };

    // Set some mock data.
    // useEffect(() => {
    //     const mockSearchResponsesData = mockSearchResponses()
    //     setSearchResponses(mockSearchResponsesData as IContentCardProps[])
    // }, [setSearchResponses])

    return (
        <Center>
            <VStack>
                <SearchInput onSearch={getSearchResults}/>

                <SearchOutput searchResponses={searchResponses}/>
            </VStack>
        </Center>
    )
}