import React, {useContext, useEffect, useState} from "react";
import {
    Box,
    Center,
    Flex,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Select,
    Spinner, Tooltip,
    VStack
} from "@chakra-ui/react";
import axios from "axios";
import {IContentCardProps, ICreator, IZCreator, UserContext} from "./Provider";
import {ContentCard} from "./Card";
import zIcon from "./Z.png"
import {Form, useNavigate, useParams} from "react-router-dom";
import {ChevronDownIcon} from "@chakra-ui/icons";
import {Helmet} from "react-helmet-async";

interface SearchInputProps {
    onSearch: (username: string, query: string) => void
    postCreator: IZCreator | undefined
}


function SearchInput({onSearch, postCreator}: SearchInputProps) {
    const [creators, setCreators] = React.useState<IZCreator[]>([]);
    const [selectedCreator, setSelectedCreator] = React.useState<IZCreator | undefined>(postCreator);
    const [query, setQuery] = React.useState('');
    const [fetchErr, setFetchErr] = useState<string | null>(null);
    const [isOpen, setIsOpen] = useState(false);

    const currentUser = useContext(UserContext)?.currentUser

    // const creator = mockCreator(username)
    useEffect(() => {
        const fetchCreator = async () => {
            try {
                const response = await axios({
                    method: 'get',
                    url: 'https://api.thez.ai/creators/try'
                })

                if (response.data === 0) {
                    setFetchErr("No creator returned from the server");
                } else {
                    setCreators(response.data as IZCreator[]);
                    console.log("Fetched " + creators.length + " creators")
                }
            } catch (error) {
                setFetchErr("Failed to fetch creators.");
                console.error("Failed to fetch creators", error);
            }
        };

        (async () => {
            console.log(`Post Creator: ${JSON.stringify(postCreator)}`)

            await fetchCreator();
        })();
    }, [currentUser]);


    // TODO: Move the following set of codes to Global React Context

    // const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    //     const username = creators.find(creator => creator.name === event.target.value)?.username;
    //     console.log(username);
    //     setSelectedCreator(username!);
    // }

    const handleCreatorSelect = (creator: IZCreator) => {
        setSelectedCreator(creator);
        setIsOpen(false);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.currentTarget.value);
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        onSearch(selectedCreator!.username, query)
    }

    const toggleDropdown = () => setIsOpen(!isOpen);

    return (
        <Box
            width="100%"
        >
            <Form onSubmit={handleSubmit}>
                <Flex
                    p={4}
                    borderBottom="1px solid gray"
                    width={'100%'}
                >
                    <InputGroup size={'lg'} width='100%'>
                        <div className="relative h-full w-full max-w-[30%] mr-2 z-10">
                            <div
                                className="flex items-center justify-between p-2 border rounded cursor-pointer bg-white"
                                onClick={toggleDropdown}
                            >
                                {selectedCreator ? (
                                    <div className="flex items-center">
                                        <img
                                            src={selectedCreator.display}
                                            alt={selectedCreator.name}
                                            className="w-5 h-5 rounded-full mr-2"
                                        />

                                        {selectedCreator.talks_about.length > 0 ? (
                                            <span>{selectedCreator.name} | {selectedCreator.talks_about}</span>
                                        ) : (
                                            <span>{selectedCreator.name}</span>
                                        )}
                                    </div>
                                ) : (
                                    <span className="text-gray-400">Select option</span>
                                )}
                                <ChevronDownIcon className="w-4 h-4"/>
                            </div>
                            {isOpen && (
                                <ul className="absolute w-full mt-1 bg-white border rounded shadow-lg max-h-60 overflow-y-auto">
                                    {creators.map((creator) => (
                                        <li
                                            key={creator.username}
                                            className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
                                            onClick={() => handleCreatorSelect(creator)}
                                        >
                                            <img
                                                src={creator.display}
                                                alt={creator.name}
                                                className="w-5 h-5 rounded-full mr-2"
                                            />
                                            {creator.talks_about.length > 0 ? (
                                                <span>{creator.name} | {creator.talks_about}</span>
                                            ) : (
                                                <span>{creator.name}</span>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        <Input
                            placeholder="Please enter your query and hit the button"
                            value={query}
                            onChange={handleInputChange}
                            minW={'70%'}
                        />

                        <InputRightElement>
                            <IconButton
                                size='md'
                                paddingX={8}
                                onClick={() => onSearch(selectedCreator!.username, query)}
                                icon={<img src={zIcon} alt="Z"/>}
                                colorScheme='whiteAlpha'
                                children=""
                                aria-label={"Z"}
                                _hover={{bg: "gray.200", cursor: "pointer"}}
                            />
                        </InputRightElement>
                    </InputGroup>
                </Flex>
            </Form>
        </Box>
    )
}

interface SearchOutputProps {
    searchResponses: IContentCardProps[]
    isLoading: boolean
}

function SearchOutput({searchResponses, isLoading}: SearchOutputProps) {
    if (isLoading) {
        return (
            <Box
                marginTop={40}
            >
                <Center height="150px">
                    <Spinner
                        thickness='5px'
                        speed='0.65s'
                        color='gray.500'
                        size='xl'
                    />
                </Center>
            </Box>
        )
    }

    return (
        <Box margin={4}>
            {searchResponses?.map((searchResponse) => ContentCard(searchResponse))}
        </Box>
    );
}

export default function Try() {
    const {post_uuid} = useParams()
    const [searchResponses, setSearchResponses] = React.useState<IContentCardProps[]>([]);
    const [postCreator, setPostCreator] = React.useState<IZCreator | undefined>(undefined);
    const [isLoading, setIsLoading] = React.useState(false)
    const navigate = useNavigate()

    const currentUser = useContext(UserContext)?.currentUser

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const url = 'https://api.thez.ai/post/preview/' + post_uuid!

            try {
                const response = await axios({
                    method: 'get',
                    url: url,
                })

                console.log(`Creator: ${JSON.stringify(response.data.of_creator)}`)

                const searchedContentCardProps: IContentCardProps = {
                    post_uuid: response.data.uuid,
                    query: response.data.query,
                    response: response.data.response,
                    links: response.data.links,
                    tags: response.data.tags,
                    creator: {
                        uuid: response.data.of_creator.uuid,
                        first_name: response.data.of_creator.first_name,
                        last_name: response.data.of_creator.last_name,
                        username: response.data.of_creator.username,
                        display: response.data.of_creator.display_url,
                        talks_about: response.data.of_creator.talks_about,
                        bio: response.data.of_creator.bio,
                    }
                }
                setSearchResponses([searchedContentCardProps]);

                const dbPostCreator: IZCreator = {
                    name: `${response.data.of_creator.first_name} ${response.data.of_creator.last_name}`,
                    username: response.data.of_creator.username,
                    display: response.data.of_creator.display_url,
                    talks_about: [],
                }

                setPostCreator(dbPostCreator)
                console.log(`DB Post Creator: ${JSON.stringify(dbPostCreator)}`)
            } catch (error) {
                console.error("Failed to fetch post", error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [post_uuid, setSearchResponses, setPostCreator]);

    const getSearchResults = (
        creatorUsername: string,
        searchQuery: string
    ) => {
        setIsLoading(true)

        axios({
            method: 'get',
            url: `https://api.thez.ai/creator/${creatorUsername}/try`,
            params: {
                query: searchQuery  // Sending searchQuery as a query parameter
            }
        }).then(response => {
            navigate(`/try/${response.data[0].post_uuid}`)
        }).catch(error => {
            setIsLoading(false)
            console.error(error)
        });
    };

    return (
        <Center>
            <UpdateOpenGraphTags ccs={searchResponses}/>

            <VStack width={"100%"}>
                {!isLoading && <SearchInput onSearch={getSearchResults} postCreator={postCreator}/>}

                <SearchOutput searchResponses={searchResponses} isLoading={isLoading}/>
            </VStack>
        </Center>
    )
}

function toTitleCase(str: string) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

type OpenGraphTags = {
    title: string,
    description: string,
    image: string,
    url: string
}

function UpdateOpenGraphTags({ccs}: { ccs: IContentCardProps[] }) {
    let openGraphTags: OpenGraphTags

    if (ccs.length > 0) {
        openGraphTags = {
            title: `${ccs[0].creator.first_name} ${ccs[0].creator.last_name} - ${toTitleCase(ccs[0].query!)}`,
            description: ccs[0].response,
            image: ccs[0].creator.display,
            url: window.location.href,
        }
    } else {
        openGraphTags = {
            title: "Z - The Last Content Platform You'll Ever Need!",
            description: "The Last Content Platform You'll Ever Need!",
            image: 'https://www.alpha.thez.ai/bg/bg3.png',
            url: window.location.href,
        }
    }

    // Use useEffect to update the tags whenever ccs changes
    useEffect(() => {
        // This function body can be used to perform actions when ccs changes,
        // but in this case, we're just relying on React to re-render the component
        // with the updated openGraphTags.
    }, [ccs]); // Dependency array includes ccs to trigger re-render

    return (
        <Helmet>
            <title>{openGraphTags.title}</title>

            <meta property="og:title" content={openGraphTags.title}/>
            <meta property="og:description" content={openGraphTags.description}/>
            <meta property="og:image" content={openGraphTags.image}/>
            <meta property="og:url" content={openGraphTags.url}/>
        </Helmet>
    );
}