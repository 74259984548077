import {Alert, AlertDescription, AlertIcon, Button, Center, CloseButton, Input, Stack,} from "@chakra-ui/react";
import React, {useState} from "react";
import axios from 'axios';
import {Form} from "react-router-dom";
import {validateEmail, validateName} from "./Core";

const waitlistLead = async (userData: {
    name: string,
    email: string;
}) => {
    const formData = new FormData();
    formData.append("name", userData["name"])
    formData.append("email", userData["email"])

    try {
        const response = await axios.post('https://api.thez.ai/users/waitlist', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        return response.status === 200;
    } catch (error) {
        console.log("Failed to waitlist", error)
        return false;
    }
}

// Take color as input
export default function Waitlist() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    const [alertType, setAlertType] = useState<"error" | "success">("error");
    const [alertMsg, setAlertMsg] = useState("");

    const handleSubmitWaitlist = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        console.log("Name: ", name);
        console.log("Email: ", email);

        if (!name || !email) {
            setAlertType("error")
            setAlertMsg("Please fill in all fields")
            return
        }
        if (!validateName(name)) {
            setAlertType("error")
            setAlertMsg("Please enter valid first and last name")
            return
        }
        if (!validateEmail(email)) {
            setAlertType("error")
            setAlertMsg("Please enter valid email")
            return
        }

        const ok = await waitlistLead({name, email});
        if (!ok) {
            setAlertType("error")
            setAlertMsg("Failed to waitlist, please try again")
            return
        }

        setAlertType("success")
        setAlertMsg("Success. Please wait for our email")
        return
    }

    return (
        <Center minHeight='100vh' minWidth='100%'>
            <Stack
                spacing={3}
                padding={5}
                bg="rgba(0, 0, 0, 0.5)" // Transparent background
                color="white" // White text color
                borderRadius="md"
                width="400px"
            >
                <Form onSubmit={handleSubmitWaitlist}>
                    <Stack
                        spacing={3}
                        paddingY={2}
                    >
                        <Input
                            name="name"
                            type='text'
                            placeholder='Name'
                            fontWeight="bold"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <Input
                            name="email"
                            type='email'
                            placeholder='Email'
                            fontWeight="bold"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <Button
                            type='submit'
                            colorScheme='grey'
                            variant='ghost'
                            width='100%'
                            fontWeight="bold"
                        >
                            💍 Waitlist
                        </Button>
                    </Stack>
                </Form>

                {alertMsg && (
                    <Alert status={alertType}>
                        <AlertIcon/>
                        <AlertDescription
                            color={'black'}
                        >
                            {alertMsg}
                        </AlertDescription>
                        <CloseButton
                            position="absolute"
                            right="8px"
                            top="8px"
                            onClick={() => setAlertMsg("")}
                        />
                    </Alert>
                )}
            </Stack>
        </Center>
    )
}