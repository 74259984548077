import React from "react"
import {createBrowserRouter, RouterProvider} from "react-router-dom"
import {UserProvider} from "./routes/Provider"
import {HelmetProvider} from "react-helmet-async"
import Layout from "./routes/Layout"
import Landing from "./routes/Landing"
import Try from "./routes/Try"
import Home from "./routes/Home"
import Creator from "./routes/Creator"
import User from "./routes/User"
import Post, {Share, View} from "./routes/Post"
import Topic from "./routes/Topic"
import Explore from "./routes/Explore";
import Error from "./Error"


const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout/>,
        errorElement: <Error/>,
        children: [
            {
                path: "/",
                element: <Landing/>,
                index: true
            },
            {
                path: "try/:post_uuid?",
                element: <Try/>,
            },
            {
                path: "home",
                element: <Home/>
            },
            {
                path: "explore",
                element: <Explore/>
            },
            {
                path: "creator/:username",
                element: <Creator/>
            },
            {
                path: "user/:username",
                element: <User/>
            },
            {
                path: "topic/:topic",
                element: <Topic/>
            },
            {
                path: "post/:post_uuid",
                element: <Post/>
            },
            {
                path: "view/:post_uuid",
                element: <View/>
            },
            {
                path: "share/:post_uuid",
                element: <Share/>
            }
        ]
    }
])


function MyApp() {
    return (
        <HelmetProvider>
            <UserProvider>
                <RouterProvider router={router}/>
            </UserProvider>
        </HelmetProvider>
    );
}

export default MyApp;