import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import MyApp from './App';
import {ChakraProvider, extendTheme} from "@chakra-ui/react";
import reportWebVitals from './reportWebVitals';

const theme = extendTheme({
    colors: {
        brand: {
            // Links
            100: "#2D3748", // Normal
            101: "#4299E1", // Hover

            200: "#06188f",
            201: "#02c1ee",
            203: "#6aa3e6",


            300: "#ec2f3a",
            301: "#f63149",
            302: "#fb6647",


            400: "#fc19a4",
            401: "#c750b2",
            402: "#9b5da5",

            501: "#c2c0c0",

            800: "#fbbb3b",
            801: "#ffe86e",
            803: "#f8cc2a",
            804: "#f8e626",

            900: "#baf2ca",
            901: "#3eb85d",
            902: "#63eda5",
            903: "#39d4cb",
            904: "#08c6c4",
        },
    },
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


root.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            <MyApp/>
        </ChakraProvider>
    </React.StrictMode>
);

reportWebVitals(console.log);