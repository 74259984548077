import React, {useContext, useEffect, useState} from "react";
import {
    Avatar,
    Box,
    Button,
    Center,
    Checkbox,
    Flex,
    Heading,
    HStack,
    Icon,
    IconButton,
    Image,
    Link,
    Text,
    Tooltip,
    VStack
} from "@chakra-ui/react";
import {FaCompass, FaHome} from "react-icons/fa";
import {ICreator, IPlatform, UserContext} from "./Provider";
import {SmallCloseIcon} from "@chakra-ui/icons";
import Cookies from 'js-cookie';
import {Form} from "react-router-dom";
import axios from "axios";
import {chunkArray} from "./Core";

export function Subscriptions() {
    const [creators, setCreators] = useState<ICreator[]>([]);
    const [selectedCreators, setSelectedCreators] = useState<{ [key: string]: boolean }>({});
    const [fetchErr, setFetchErr] = useState<string | null>(null);

    const currentUser = useContext(UserContext)?.currentUser

    useEffect(() => {
        const fetchCreators = async () => {
            const token = currentUser?.token || ""

            try {
                const response = await axios({
                    method: 'get',
                    url: 'https://api.thez.ai/creators',
                    headers: {
                        'Authorization': `Bearer ${token}` // Include the token in the Authorization header
                    }
                })

                if (response.data.creators === 0) {
                    setFetchErr("No creators returned from the server");
                } else {
                    console.log("Current user subscriptions: " + currentUser?.subscriptions)

                    console.log("Fetched " + response.data.creators.length + " creators")

                    // Keep only the creators that the user is subscribed to
                    const filteredCreators = response.data.creators.filter((creator: ICreator) => {
                        return currentUser?.subscriptions?.includes(creator.username)
                    })
                    console.log("Filtered " + filteredCreators.length + " creators")
                    setCreators(filteredCreators as ICreator[]);
                }
            } catch (error) {
                setFetchErr("Failed to fetch creators.");
                console.error("Failed to fetch creators", error);
            }
        };

        (async () => {
            await fetchCreators();
        })();
    }, [currentUser]);

    useEffect(() => {
        const selectedCreatorKeys = Object.keys(selectedCreators).filter((key) => selectedCreators[key]);
        console.log("Selected Creators:", selectedCreatorKeys);
    }, [selectedCreators]);

    if (fetchErr) {
        return <Text>Error: {fetchErr}</Text>;
    }

    const handleCheckboxChange = (uuid: string) => {
        setSelectedCreators((prevState) => {
            const newState = {...prevState};
            if (newState[uuid]) {
                delete newState[uuid];
            } else {
                newState[uuid] = true;
            }
            return newState;
        })
    }

    const creatorRows = chunkArray(creators)

    return (
        <Box>
            <Heading
                as='h4'
                size='md'
                paddingY={4}
                textColor='black'
            >
                Subscriptions
            </Heading>
            {creatorRows.map((row, rowIndex) => (
                <HStack key={rowIndex} spacing={0}>
                    {row.map((creator) => (
                        <Box
                            key={creator.uuid}
                            width='33%'
                            border='1px solid white'
                            position='relative'
                            _hover={{border: '1px solid gray'}}
                        >
                            <Link href={`/creator/${creator.username}`}>
                                <Image
                                    objectFit='cover'
                                    src={creator.display}
                                    alt={`${creator.first_name} ${creator.last_name}`}
                                />
                            </Link>
                            {/*<Checkbox*/}
                            {/*    position='absolute'*/}
                            {/*    size='lg'*/}
                            {/*    right='0'*/}
                            {/*    bottom='0'*/}
                            {/*    isChecked={!!selectedCreators[creator.uuid]}*/}
                            {/*    onChange={() => handleCheckboxChange(creator.uuid)}*/}
                            {/*/>*/}
                        </Box>
                    ))}
                </HStack>
            ))}
        </Box>
    );
}

export function Platforms() {
    const [platforms, setPlatforms] = useState<IPlatform[]>([]);
    const [selectedPlatforms, setSelectedPlatforms] = useState<{ [key: string]: boolean }>({});
    const [fetchErr, setFetchErr] = useState<string | null>(null);

    const currentUser = useContext(UserContext)?.currentUser

    useEffect(() => {
        const fetchPlatforms = async () => {
            const token = currentUser?.token || ""

            try {
                const response = await axios({
                    method: 'get',
                    url: 'https://api.thez.ai/platforms',
                    headers: {
                        'Authorization': `Bearer ${token}` // Include the token in the Authorization header
                    }
                })

                if (response.data.platforms === 0) {
                    setFetchErr("No platforms returned from the server");
                } else {
                    setPlatforms(response.data.platforms);
                    console.log("Fetched " + platforms.length + " platforms")
                }
            } catch (error) {
                setFetchErr("Failed to fetch platforms.");
                console.error("Failed to fetch platforms", error);
            }
        };

        (async () => {
            await fetchPlatforms();
        })();
    }, [currentUser]);

    useEffect(() => {
        const selectedPlatformKeys = Object.keys(selectedPlatforms).filter((key) => selectedPlatforms[key]);
        console.log("Selected Platforms:", selectedPlatformKeys);
    }, [selectedPlatforms]);

    if (fetchErr) {
        return <Text>Error: {fetchErr}</Text>;
    }

    const handleCheckboxChange = (uuid: string) => {
        setSelectedPlatforms((prevState) => {
            const newState = {...prevState};
            if (newState[uuid]) {
                delete newState[uuid];
            } else {
                newState[uuid] = true;
            }
            return newState;
        })
    }

    return (
        <Box>
            <Heading
                as='h4'
                size='md'
                paddingY={4}
                textColor='black'
            >
                Platforms
            </Heading>
            {chunkArray(platforms).map((row, rowIndex) => (
                <HStack key={rowIndex} spacing={0}>
                    {row.map((platform) => (
                        <Box
                            key={platform.uuid}
                            width='33%'
                            border='1px solid white'
                            position='relative'
                            _hover={{border: '1px solid gray'}}
                        >
                            <Image
                                objectFit='cover'
                                src={platform.display}
                                alt={platform.name}
                            />
                            <Checkbox
                                position='absolute'
                                size='lg'
                                right='0'
                                bottom='0'
                                isChecked={!!selectedPlatforms[platform.uuid]}
                                onChange={() => handleCheckboxChange(platform.uuid)}
                            />
                        </Box>
                    ))}
                </HStack>
            ))}
        </Box>
    );
}

export default function Leftbar() {
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        Cookies.remove('user');
        console.log("Successfully logout user");
        window.location.reload()
        return
    };

    const buttons = [
        {text: "Home", icon: FaHome, link: "/home"},
        {text: "Explore", icon: FaCompass, link: "/explore"},
    ];

    const renderButton = (text: string, IconComponent: any, link: string) => (
        <Link href={link}>
            <Button
                colorScheme="gray"
                variant="ghost" // No outline initially
                leftIcon={<Icon as={IconComponent}/>}
                width="100%"
                justifyContent="flex-start"
                paddingY={6} // Padding around the buttons from each other
                fontSize="lg"
                borderRadius="full" // Circular rounded border
                _hover={{
                    borderColor: "gray.500",
                    borderWidth: "1px",
                    variant: "outline",
                }}
            >
                {text}
            </Button>
        </Link>
    );

    const currentUser = useContext(UserContext)?.currentUser
    const userLink = "/user/" + currentUser?.username

    return (
        <Box
            w="100%"
            p={4}
            color="white"
            h="100%"
            position="sticky"
            top={0}
            overflowY="hidden"
            borderRight="1px solid gray"
        >
            <Flex flexDirection="column" h="100%">
                <VStack spacing={4} align="stretch">
                    <Box w="100%">
                        <Center>
                            <Image src="/logo.png" alt="Logo" maxW="30%"/>
                        </Center>
                    </Box>

                    <Box w="100%">
                        {buttons.map((button) => renderButton(button.text, button.icon, button.link))}
                    </Box>
                </VStack>

                <Box>
                    <Subscriptions/>
                </Box>

                {/*<Box>*/}
                {/*    <Platforms/>*/}
                {/*</Box>*/}

                <Center>
                    <Box
                        mt="auto"
                        textAlign="center"
                        alignItems="flex-end"
                        position="fixed"
                        bottom={5}
                        _hover={{borderColor: "gray.300", borderWidth: "1px", borderRadius: "full"}}
                        p={4}
                    >
                        <Flex alignItems="center" justifyContent="center">
                            <Avatar
                                name={currentUser?.name}
                                src={currentUser?.display}
                                size="md"
                            />

                            <Box
                                ml={3}
                                textAlign="left"
                            >
                                <Link href={userLink}>
                                    <Text
                                        as="span"
                                        fontSize="lg"
                                        fontWeight="bold"
                                        color="black"
                                        _hover={{textDecoration: "underline"}}
                                    >
                                        {currentUser?.name}
                                    </Text>
                                </Link>
                                <Box fontSize="sm" color="gray.600">
                                    @{currentUser?.username}
                                </Box>
                            </Box>

                            <Tooltip label="Logout" placement="right">
                                <Form onSubmit={handleSubmit}>
                                    <IconButton
                                        type='submit'
                                        marginLeft={2}
                                        isRound={true}
                                        variant='solid'
                                        aria-label='Done'
                                        fontSize='20px'
                                        icon={<SmallCloseIcon/>}
                                    />
                                </Form>
                            </Tooltip>
                        </Flex>
                    </Box>
                </Center>
            </Flex>
        </Box>
    );
}