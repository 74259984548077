import {Avatar, Box, Center, Divider, Flex, Heading, Link, Text} from "@chakra-ui/react";
import React, {useContext, useEffect, useState} from "react";
import {BlueTick} from "./Core";
import {ICreator, UserContext} from "./Provider";
import axios from "axios";

export default function Explore() {
    const [creators, setCreators] = useState<ICreator[]>([]);
    const [fetchErr, setFetchErr] = useState<string | null>(null);

    const currentUser = useContext(UserContext)?.currentUser

    useEffect(() => {
        const fetchCreators = async () => {
            const token = currentUser?.token || ""

            try {
                const response = await axios({
                    method: 'get',
                    url: 'https://api.thez.ai/creators',
                    headers: {
                        'Authorization': `Bearer ${token}` // Include the token in the Authorization header
                    }
                })

                if (response.data.creators === 0) {
                    setFetchErr("No creators returned from the server");
                } else {
                    setCreators(response.data.creators);
                    console.log("Fetched " + creators.length + " creators")
                }
            } catch (error) {
                setFetchErr("Failed to fetch creators.");
                console.error("Failed to fetch creators", error);
            }
        };

        (async () => {
            await fetchCreators();
        })();
    }, [currentUser]);

    if (fetchErr) {
        return <Text>Error: {fetchErr}</Text>;
    }

    // TODO: Show followers and subscribers count on the explore page


    return (
        <Box>
            <Box
                my={10}
            >
                <Center>
                    <Heading as='h3' size='lg'>
                        Creators
                    </Heading>
                </Center>
            </Box>

            <Box
                m={4}
                p={4}
                borderRadius="md"
                border="1px solid"
                borderColor="gray.200"
                _hover={{
                    backgroundColor: "gray.50",
                    borderWidth: "2px solid",
                }}
            >
                {creators.map((creator, index) => (
                    <React.Fragment key={index}>
                        <Flex alignItems="left" justifyContent="left" py='2'>
                            <Avatar
                                name={creator.first_name + " " + creator.last_name}
                                src={creator.display}
                                size="md"
                            />

                            <Box
                                ml={3}
                                textAlign="left"
                            >
                                <Link href={`/creator/${creator.username}`}>
                                    <Text
                                        as="span"
                                        fontSize="lg"
                                        fontWeight="bold"
                                        color="black"
                                        _hover={{textDecoration: "underline"}}
                                    >
                                        {creator.first_name + " " + creator.last_name}
                                        <BlueTick size={'sm'}/>
                                    </Text>
                                </Link>
                                <Box fontSize="sm" color="gray.600">
                                    @{creator.username}
                                </Box>
                            </Box>

                            {/*/!* Subscribe button *!/*/}
                            {/*<Button*/}
                            {/*    colorScheme="gray"*/}
                            {/*    size="md"*/}
                            {/*    ml="auto"*/}
                            {/*>*/}
                            {/*    {exploreUser.creator ? "Subscribe" : "Follow"}*/}
                            {/*</Button>*/}
                        </Flex>
                        {index < creators.length - 1 && (
                            <Divider borderColor="gray.300" opacity={0.5} my={2}/>
                        )}
                    </React.Fragment>
                ))}
            </Box>
        </Box>
    )
}