import {IChunkable} from "./Provider";
import {Box, Text} from "@chakra-ui/react";
import React from "react";

export function randInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function validateName(name: string): boolean {
    const nameSplit = name.split(" ")
    if (nameSplit.length !== 2) {
        return false
    } else {
        const nameRegex = /^[a-zA-Z]+$/
        for (let i = 0; i < nameSplit.length; i++) {
            if (!nameRegex.test(nameSplit[i])) {
                return false
            }
        }
    }
    return true
}

export function validateEmail(email: string): boolean {
    const emailRegex = /^\S+@\S+\.\S+$/
    return emailRegex.test(email)
}

export function fmtPlatformName(platform: string): string {
    if (platform === "youtube") {
        return "YouTube"
    } else if (platform === "instagram") {
        return "Instagram"
    } else if (platform === "libgen") {
        return "Books"
    } else if (platform === "wikipedia") {
        return "Wikipedia"
    } else if (platform === "twitter") {
        return "Twitter"
    } else {
        return ""
    }
}

export function pickRandom(options: string[]): string {
    return options[Math.floor(Math.random() * options.length)]
}

export function chunkArray<T extends IChunkable>(arr: T[]): T[][] {
    const chunkSize = 3;
    const results: T[][] = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        results.push(arr.slice(i, i + chunkSize));
    }
    return results;
}

export type LinkCategories = {
    youtube: string[]
    instagram: string[]
    libgen: string[]
    // TODO: Add other platforms here
}

export function categorizeLinks(links: string[]): LinkCategories {
    console.log(links)

    const youtubeLinkGroups: Record<string, string[]> = {}

    const categorizedLinks: LinkCategories = {
        youtube: [],
        instagram: [],
        libgen: [],
    }

    links.forEach((link) => {
        if (link.startsWith("https://www.youtube.com")) {
            const prefix = link.split('&t=')[0]
            if (!youtubeLinkGroups[prefix]) {
                youtubeLinkGroups[prefix] = []
            }
            youtubeLinkGroups[prefix].push(link)
        } else if (link.startsWith("https://www.instagram.com")) {
            categorizedLinks.instagram.push(link)
        } else {
            categorizedLinks.libgen.push(link)
        }
    })

    // Keep the links that have the timestamps that are farthest out
    Object.values(youtubeLinkGroups).forEach((group) => {
        const sortedGroup = group
            .map((link) => parseFloat(link.split('&t=')[1]))
            .sort((a, b) => a - b)

        const selectedTimestamps = [sortedGroup[0]]
        for (let i = 1; i < sortedGroup.length; i++) {
            if (sortedGroup[i] - selectedTimestamps[selectedTimestamps.length - 1] > 300) {
                selectedTimestamps.push(sortedGroup[i])
            }
        }

        selectedTimestamps.slice(0, Math.min(3, selectedTimestamps.length)).forEach((timestamp) => {
            categorizedLinks.youtube.push(`${group[0].split('&t=')[0]}&t=${timestamp}s`)
        })
    })

    console.log(`Categorized links: ${JSON.stringify(categorizedLinks)}`)

    // Remove duplicates from Instagram and other links
    categorizedLinks.youtube = categorizedLinks.youtube.slice(0, Math.min(5, categorizedLinks.youtube.length))
    categorizedLinks.instagram = Array.from(new Set(categorizedLinks.instagram))
    categorizedLinks.libgen = Array.from(new Set(categorizedLinks.libgen))

    console.log(`Sliced links: ${JSON.stringify(categorizedLinks)}`)

    return categorizedLinks
}

interface BlueTickProps {
    size: string
}

export function BlueTick({size}: BlueTickProps) {
    function getSize(size: string): string {
        if (size === 'md') {
            return '16px'
        } else if (size === 'lg') {
            return '20px'
        }
        return '12px'
    }

    return (
        <Box
            as="span"
            borderRadius="full"
            bg="blue.500"
            p={2}
            ml={2}
            w={getSize(size)}
            h={getSize(size)}
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
        >
            <Text fontSize={size} color="white">
                {' '}✓
            </Text>
        </Box>
    )
}