import {useParams} from "react-router-dom";
import {Box, Heading, Tab, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import React, {useContext, useEffect, useState} from "react";
import {ICreator, ICreatorContents, UserContext} from "./Provider";
import {BioDataCard, ExtContentCard} from "./Card";
import {fmtPlatformName} from "./Core";
import axios from "axios";


export default function Creator() {
    const [creator, setCreator] = useState<ICreator>()
    const [contents, setContents] = useState<ICreatorContents[]>([]);
    const [fetchErr, setFetchErr] = useState<string | null>(null);

    const currentUser = useContext(UserContext)?.currentUser

    const {username} = useParams();
    console.log("Loading creator@" + username)

    // const creator = mockCreator(username)
    useEffect(() => {
        const fetchCreator = async () => {
            const url = 'https://api.thez.ai/creator/' + username
            const token = currentUser?.token || ""

            try {
                const response = await axios({
                    method: 'get',
                    url: url,
                    headers: {
                        'Authorization': `Bearer ${token}` // Include the token in the Authorization header
                    }
                })

                if (response.data === 0) {
                    setFetchErr("No creator returned from the server");
                } else {
                    setCreator(response.data as ICreator);
                    console.log("Fetched creator: " + creator)
                }
            } catch (error) {
                setFetchErr("Failed to fetch creator.");
                console.error("Failed to fetch creator", error);
            }
        };

        (async () => {
            await fetchCreator();
        })();
    }, [currentUser, username]);

    useEffect(() => {
        const fetchContents = async () => {
            const url = 'https://api.thez.ai/creator/' + creator?.username + '/contents'
            const token = currentUser?.token || ""

            try {
                const response = await axios({
                    method: 'get',
                    url: url,
                    headers: {
                        'Authorization': `Bearer ${token}` // Include the token in the Authorization header
                    }
                })

                if (response.data === 0) {
                    setFetchErr("No contents returned from the server");
                } else {
                    setContents(response.data as ICreatorContents[]);
                    console.log("Fetched " + contents.length + " contents")
                }
            } catch (error) {
                setFetchErr("Failed to fetch contents.");
                console.error("Failed to fetch contents", error);
            }
        };

        (async () => {
            await fetchContents();
        })();
    }, [currentUser, creator]);

    const platformContents = categorizeByPlatform(contents);

    return (
        <Box>
            <BioDataCard
                type='creator'
                name={`${creator?.first_name} ${creator?.last_name}`}
                username={creator?.username!}
                display={creator?.display!}
                topics={creator?.talks_about}
                bio={creator?.bio}
                connected={creator?.connected}
            />

            <Box
                maxW="100%"
            >
                {Object.keys(platformContents).length > 0 && (
                    <Tabs>
                        <TabList>
                            {Object.keys(platformContents).map((platform, index) => (
                                <Tab
                                    key={index}
                                    px={6}
                                >
                                    <Heading as='h4' size='md'>
                                        {fmtPlatformName(platform)}
                                    </Heading>
                                </Tab>
                            ))}
                        </TabList>

                        <TabPanels p={6}>
                            {Object.keys(platformContents).map((platform, index) => (
                                <TabPanel
                                    key={index}
                                    minW='100%'
                                    maxW='100%'
                                >
                                    <ExtContentCard
                                        platform={platform}
                                        links={platformContents[platform][0]}
                                        titles={platformContents[platform][1]}
                                    />
                                </TabPanel>
                            ))}
                        </TabPanels>
                    </Tabs>
                )}
            </Box>
        </Box>
    )
}

const categorizeByPlatform = (data: ICreatorContents[]): Record<string, [string[], string[]]> => {
    const categorizedData: Record<string, [string[], string[]]> = {};
    data.forEach((item) => {
        const {platform, links, titles} = item;
        categorizedData[platform] = [links, titles];
    });
    return categorizedData;
}