import React, {useContext, useEffect, useState} from "react";
import {
    Avatar,
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Grid,
    Heading,
    HStack,
    Icon,
    IconButton,
    Image,
    Link,
    List,
    ListItem,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    Tooltip,
    useBreakpointValue,
    useDisclosure,
    VStack
} from "@chakra-ui/react";
import {FaBookmark, FaGlobe, FaShare, FaThumbsDown, FaThumbsUp} from "react-icons/fa";
import {EmbedInstagram, EmbedPost, EmbedYouTube} from "./Embed";
import {IContentCardProps, IExtContentCardProps, UserContext} from "./Provider";
import {dislikePost, likePost, publicPost, savePost, sharePost, unpublicPost, unsavePost} from "./api/post";
import {subscribeCreator, unsubscribeCreator} from "./api/creator";
import {followUser, unfollowUser} from "./api/user";
import {BlueTick, categorizeLinks} from "./Core";
import {TopicButton} from "./Topic";
import {FeedbackPopup} from "./Popup";

export interface BioDataProps {
    type: string
    name: string
    username: string
    display: string
    topics?: string[]
    bio?: string
    connected?: boolean
}

export function BioDataCard({type, name, username, display, topics, bio, connected}: BioDataProps) {
    const {currentUser, setCurrentUser} = useContext(UserContext)!;
    const [isInit, setIsInit] = useState(false)
    const [isToggled, setIsToggled] = useState(false)

    useEffect(() => {
        if (!isInit) {
            setIsToggled(connected || false)
        }
    }, [isInit, connected])

    const handleClick = () => {
        setIsInit(true)

        const currentUserToken = currentUser?.token || ''
        if (isToggled) {
            if (type === 'user') {
                unfollowUser({username: username, token: currentUserToken}).then((response) => {
                    console.log("unfollowUser response: " + response)
                })
            } else {
                unsubscribeCreator({username: username, token: currentUserToken}).then((response) => {
                    console.log("unsubscribeCreator response: " + response)
                })

                const updatedCurrentUser = {...currentUser};
                if (currentUser?.subscriptions) {
                    updatedCurrentUser.subscriptions = currentUser.subscriptions.filter(item => item !== username)
                }
                setCurrentUser(updatedCurrentUser)
            }
        } else {
            if (type === 'user') {
                followUser({username: username, token: currentUserToken}).then((response) => {
                    console.log("followUser response: " + response)
                })
            } else {
                subscribeCreator({username: username, token: currentUserToken}).then((response) => {
                    console.log("subscribeCreator response: " + response)
                })

                const updatedCurrentUser = {...currentUser};
                if (currentUser?.subscriptions) {
                    updatedCurrentUser.subscriptions = [...currentUser.subscriptions, username]
                }
                setCurrentUser(updatedCurrentUser)
            }
        }
        setIsToggled(!isToggled)
    }

    return (
        <Box
            mb={2}
        >
            <HStack
                w="100%"
                spacing={4}
            >
                <Box
                    flex="0.8"
                    p={6}
                    textAlign="left"
                    alignItems="flex-start"
                >
                    <Heading>
                        {name}{' '}{type === 'creator' && <BlueTick size={'lg'}/>}
                    </Heading>
                    <Text
                        as='i'
                        color='gray'
                    >
                        @{username}
                    </Text>
                    <Text
                        mt={2}
                    >
                        {bio}
                    </Text>
                    <Flex
                        mt={2}
                    >
                        {topics?.map((topic, index) => (
                            <TopicButton
                                index={index}
                                topic={topic}
                            />
                        ))}
                    </Flex>
                </Box>
                <Box
                    flex="0.2"
                    p={8}
                >
                    <VStack>
                        <Box
                            borderRadius="md"
                            border="2px solid gray.300"
                            overflow="hidden"
                            w="130px"
                            h="130px"
                            p={2}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            margin="auto" // Center align horizontally
                        >
                            <Image
                                objectFit='cover'
                                src={display}
                                alt={name}
                                borderRadius="md"
                                w='100%'
                                h='100%'
                            />
                        </Box>

                        {/*Follow/Subscribe button*/}
                        {currentUser?.username !== username && (
                            <Button
                                colorScheme="gray"
                                size='md'
                                onClick={handleClick}
                            >
                                {isToggled
                                    ? (type === 'creator' ? 'Subscribed' : 'Following')
                                    : (type === 'creator' ? 'Subscribe' : 'Follow')}
                            </Button>
                        )}
                    </VStack>
                </Box>
            </HStack>

            <Divider mt={3} borderColor="blackAlpha.500" mb={3} width="100%"/>
        </Box>
    )
}

// TODO: Add a responsive version of ContentCard for mobile screens
export function ContentCard(
    {post_uuid, query, response, links, creator, tags, user, visibility, status}: IContentCardProps
) {
    const {currentUser} = useContext(UserContext)!;
    const [isInit, setIsInit] = useState(false)
    const [isLiked, setIsLiked] = React.useState<boolean>(false)
    const [isDisliked, setIsDisliked] = React.useState<boolean>(false)
    const [isPublic, setIsPublic] = React.useState<boolean>(false);
    const [isSaved, setIsSaved] = React.useState<boolean>(false);
    const [isShared, setIsShared] = React.useState<boolean>(false);
    const isMobile = useBreakpointValue({base: true, md: true, lg: true, xl: true});

    useEffect(() => {
        if (!isInit) {
            setIsPublic(visibility === 'internal' || visibility === 'external')
            setIsSaved(status === 'saved')
            setIsShared(visibility === 'external')
        }
    }, [isInit, visibility, status])

    const handleLikeChange = () => {
        if (isLiked) {
            return
        }
        likePost({postUUID: post_uuid}).then((response) => {
            console.log("likePost response: " + response)
        })
        setIsLiked(true);
        return
    }

    const handleDislikeChange = () => {
        if (isDisliked) {
            onOpen()
            return
        }
        dislikePost({postUUID: post_uuid}).then((response) => {
            console.log("dislikePost response: " + response)
        })
        setIsDisliked(true);
        onOpen()
        return
    }

    const handleVisibilityChange = () => {
        setIsInit(true)

        const currentUserToken = currentUser?.token || ''
        if (isPublic) {
            unpublicPost({postUUID: post_uuid, token: currentUserToken}).then((response) => {
                console.log("unpublicPost response: " + response)
            })
        } else {
            publicPost({postUUID: post_uuid, token: currentUserToken}).then((response) => {
                console.log("publicPost response: " + response)
            })
        }
        setIsPublic((prevState) => !prevState);
    }

    const handleStatusChange = () => {
        setIsInit(true)

        const currentUserToken = currentUser?.token || ''
        if (isSaved) {
            unsavePost({postUUID: post_uuid, token: currentUserToken}).then((response) => {
                console.log("unsavePost response: " + response)
            })
        } else {
            savePost({postUUID: post_uuid, token: currentUserToken}).then((response) => {
                console.log("savePost response: " + response)
            })
        }
        setIsSaved((prevState) => !prevState);
    }

    const handleShareChange = () => {
        setIsInit(true)
        setIsShared(true) // Unlike the other two, this is a one-time action

        const currentUserToken = currentUser?.token || ''
        sharePost({postUUID: post_uuid, token: currentUserToken}).then((response) => {
            console.log("sharePost response: " + response)
        })

        // onOpen() // Open the share popup
    }


    const categorizedLinks = categorizeLinks(links)
    const shortBio = creator.bio ? creator.bio.substring(0, 25) : '';
    const {isOpen, onOpen, onClose} = useDisclosure();

    const StackType = isMobile ? VStack : HStack;

    return (
        <Box
            paddingY={2}
        >
            {response &&
                <Box
                    p={4}
                    borderRadius="md"
                    border="1px solid"
                    borderColor="gray.200"
                    _hover={{
                        backgroundColor: "gray.50",
                        borderWidth: "2px solid",
                    }}
                >
                    {/*Header*/}
                    <Grid
                        templateColumns={{base: "0.7fr 0.3fr", md: "0.5fr 0.5fr"}}
                    >
                        <Flex
                            direction="row"
                            alignItems="center"
                        >
                            <img src={creator.display} className={"rounded-full w-12 h-12 shadow-md"}/>

                            <Box ml={{base: 2, md: 3}}>
                                <Link href={`/creator/${creator.username}`}>
                                    <Text
                                        as="span"
                                        fontWeight="bold"
                                    >
                                        {creator.first_name} {creator.last_name}
                                    </Text>
                                </Link>
                                <Text
                                    as="span"
                                    color="gray.600"
                                    display={isMobile ? "block" : "inline"}
                                >
                                    {' '}@{creator.username}
                                </Text>
                                {!isMobile && <Tooltip
                                    label={creator.bio || ""}
                                    placement="right"
                                >
                                    <Text color="gray.600">
                                        {shortBio}
                                        {shortBio.length > 24 ? "..." : ""}
                                    </Text>
                                </Tooltip>}
                            </Box>
                        </Flex>
                        <Flex justifyContent="right">
                            <HStack spacing={4}>
                                <Tooltip label={isLiked ? 'Remove' : 'Like'} placement="left">
                                    <IconButton
                                        colorScheme={'gray'}
                                        variant='outline'
                                        aria-label={isLiked ? 'Remove' : 'Like'}
                                        icon={<Icon as={FaThumbsUp} color={isLiked ? 'blue.400' : 'gray.700'}/>}
                                        onClick={handleLikeChange}
                                    />
                                </Tooltip>
                                <Tooltip label={isDisliked ? 'Remove' : 'Dislike'} placement="right">
                                    <IconButton
                                        colorScheme={'gray'}
                                        variant='outline'
                                        aria-label={isDisliked ? 'Remove' : 'Dislike'}
                                        icon={<Icon as={FaThumbsDown} color={isDisliked ? 'blue.400' : 'gray.700'}/>}
                                        onClick={handleDislikeChange}
                                    />
                                </Tooltip>
                            </HStack>
                        </Flex>
                        {visibility && status && (
                            <Flex justifyContent="right">
                                <HStack spacing={4}>
                                    <Tooltip label={isPublic ? 'Public' : 'Private'} placement="left">
                                        <IconButton
                                            colorScheme={'gray'}
                                            variant='outline'
                                            aria-label={isPublic ? 'Public' : 'Private'}
                                            icon={<Icon as={FaGlobe} color={isPublic ? 'blue.400' : 'gray.700'}/>}
                                            onClick={handleVisibilityChange}
                                        />
                                    </Tooltip>
                                    <Tooltip label={isSaved ? 'Unsave' : 'Save'} placement="top">
                                        <IconButton
                                            colorScheme={'gray'}
                                            variant='outline'
                                            aria-label={isSaved ? 'Unsave' : 'Save'}
                                            icon={<Icon as={FaBookmark} color={isSaved ? 'blue.400' : 'gray.700'}/>}
                                            onClick={handleStatusChange}
                                        />
                                    </Tooltip>
                                    <Tooltip label="Share" placement="right">
                                        <IconButton
                                            colorScheme="gray"
                                            variant='outline'
                                            aria-label='Share'
                                            icon={<Icon as={FaShare} color={isShared ? 'blue.400' : 'gray.700'}/>}
                                            onClick={handleShareChange}
                                        />
                                    </Tooltip>
                                </HStack>
                            </Flex>
                        )}
                    </Grid>

                    {/*Query*/}
                    {query && (
                        <Heading
                            as='h4'
                            size='md'
                            paddingY="3"
                        >
                            {query}
                        </Heading>
                    )}

                    {/*Response*/}
                    <Text
                        paddingY="3"
                    >
                        {response}
                    </Text>

                    {/*References*/}
                    <Tabs variant="enclosed">
                        <Flex justifyContent="center">
                            <TabList>
                                {categorizedLinks.youtube.length > 0 && <Tab>YouTube</Tab>}
                                {categorizedLinks.instagram.length > 0 && <Tab>Instagram</Tab>}
                                {categorizedLinks.libgen.length > 0 && <Tab>Books</Tab>}
                            </TabList>
                        </Flex>
                        <TabPanels>
                            {categorizedLinks.youtube.length > 0 &&
                                <TabPanel>
                                    <Box width="100%" overflowX="scroll">
                                        <StackType spacing={4}>
                                            {categorizedLinks.youtube.map((link, index) => (
                                                <Box key={index} width={"100%"}>
                                                    <EmbedYouTube URL={link} justify={'center'} autoplay={index == 0}/>
                                                </Box>
                                            ))}
                                        </StackType>
                                    </Box>
                                </TabPanel>
                            }
                            {categorizedLinks.instagram.length > 0 &&
                                <TabPanel>
                                    <Box width="100%" overflowX="scroll">
                                        <StackType spacing={4}>
                                            {categorizedLinks.instagram.map((link, index) => (
                                                <Box key={index}>
                                                    <EmbedInstagram URL={link} justify={'center'}/>
                                                </Box>
                                            ))}
                                        </StackType>
                                    </Box>
                                </TabPanel>
                            }
                            {categorizedLinks.libgen.length > 0 &&
                                <TabPanel>
                                    <Box width="100%" overflowX="scroll">
                                        <StackType spacing={4}>
                                            {categorizedLinks.libgen.map((link, index) => (
                                                <Box key={index}>
                                                    <Text>{link}</Text>
                                                </Box>
                                            ))}
                                        </StackType>
                                    </Box>
                                </TabPanel>
                            }
                        </TabPanels>
                    </Tabs>

                    {/*Tags*/}
                    <Center>
                        <Flex
                            mt={2}
                        >
                            {tags?.map((topic, index) => (
                                <TopicButton
                                    index={index}
                                    topic={topic}
                                />
                            ))}
                        </Flex>
                    </Center>

                    {/*Share*/}
                    {/*<SharePopup*/}
                    {/*    postID={post_uuid}*/}
                    {/*    isOpen={isOpen}*/}
                    {/*    onClose={onClose}*/}
                    {/*/>*/}

                    {/*Feedback*/}
                    <FeedbackPopup
                        postID={post_uuid}
                        isOpen={isOpen}
                        onClose={onClose}
                    />
                </Box>
            }
        </Box>
    )
}

export function ExtContentCard({platform, links, titles}: IExtContentCardProps) {
    if (platform === "instagram") {
        return (
            <Box>
                <Heading>
                    Coming soon!
                </Heading>
            </Box>
        )
    }

    return (
        <VStack spacing={4}>
            {links.map((link, idx) => (
                <Box
                    key={idx}
                    w="100%"
                    borderWidth="1px"
                    borderRadius="md"
                    _hover={{bg: "gray.100"}}
                    p={4}
                >
                    <HStack spacing={4} w="100%">
                        <Box
                            flex="0.4"
                        >
                            <EmbedPost
                                platform={platform}
                                title={titles[idx]}
                                URL={link}
                                justify={'left'}
                            />
                        </Box>
                        <Box
                            flex="0.6"
                            textAlign="left"
                            alignItems="flex-start"
                        >
                            <Heading
                                as="h4"
                                size='md'
                                mb={4}
                            >
                                Popular
                            </Heading>
                            <List spacing={2}>
                                {getRandomQuestions().map((question, idx) => (
                                    <ListItem
                                        key={idx}
                                        borderWidth="1px"
                                        borderRadius="md"
                                        padding="4px"
                                        _hover={{color: "blue.500"}}
                                    >
                                        <Link href="#">
                                            {question}
                                        </Link>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </HStack>
                </Box>
            ))}
        </VStack>
    );
}

function getRandomQuestions(): string[] {
    const questions = [
        // Health
        "What are the benefits of a balanced diet?",
        "How does exercise impact mental health?",
        "Is organic food really better?",
        "How do vaccines work?",
        "What's the importance of hydration?",
        "How effective are mental health apps?",
        "Why is sleep important?",
        "Does intermittent fasting work?",
        "What is herd immunity?",
        "How do you manage chronic pain?",

        // Psychology
        "What is cognitive dissonance?",
        "How do emotions affect decision-making?",
        "What is the placebo effect?",
        "How does stress affect the body?",
        "What is mindfulness?",
        "How does social media affect mental health?",
        "What's the impact of nature on well-being?",
        "Is multitasking effective?",
        "What causes procrastination?",
        "How do video games affect the brain?",

        // Life
        "What is the key to happiness?",
        "How important is work-life balance?",
        "What makes a good leader?",
        "How do you cultivate creativity?",
        "What is the value of mentorship?",
        "How can one achieve financial freedom?",
        "What's the importance of lifelong learning?",
        "How do you maintain friendships?",
        "What's the key to a successful relationship?",
        "Is a college degree necessary?",

        // Startups
        "What's the most important factor in startup success?",
        "How do you validate a business idea?",
        "When should a startup consider pivoting?",
        "How do you measure product-market fit?",
        "What are the key metrics for startup success?",
        "How do you scale a business?",
        "What is bootstrapping?",
        "What's the role of a startup incubator?",
        "When should you seek venture capital?",
        "How do you exit a startup?",

        // Technology
        "How is AI impacting our daily lives?",
        "What are the ethical implications of data mining?",
        "What is quantum computing?",
        "How secure is cloud storage?",
        "What are the basics of cybersecurity?",
        "How is 5G different from 4G?",
        "What are the applications of IoT?",
        "How does blockchain work?",
        "What is augmented reality?",
        "What are the challenges of remote work technology?",
    ];


    const shuffled = questions.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 3);
}