import {Flex, Text} from "@chakra-ui/react";
import React from "react";


export default function Preview() {
    return (
        <Flex
            justifyContent="center"
            alignItems="center"
            height="100vh"
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
        >
            <Text fontFamily="AvenirNextBold" fontSize='7xl' color={"white"}>
                Coming soon
            </Text>
        </Flex>
    )
}